import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useEffect } from 'react';

import { UserSettingsStoreState, UserSettingsStore } from '../../state/userSettingsStore';
import { FavoriteSwing, Layout, getUsersTags } from '@core';
import { useGlobalStore } from '../../state/globalStore';
import { useSession } from '../hooks/useSession';
import { useActivityContext } from './ActivityContext';
import {
    getAllUINodes,
    getUserActiveLayoutID,
    getUserLayout,
    setUserActiveLayoutID,
    updateUserLayout,
    createUserLayout,
    deleteUserLayout,
    getAllCorridors,
    getUserLayouts,
    getUserSettingsStore,
} from '@data';

interface UserSettingsProviderProps {
    children: React.ReactNode;
    store: UserSettingsStoreState;
}

interface UserSettingsContextType {
    settings: UserSettingsStoreState | null;
    getSettingsStore: () => void;
    setSettingsStore: (userSettingsStore: UserSettingsStore) => void;
    markSwingAsFavorite: (swing: FavoriteSwing) => void;
    getCorridors: () => void;
    getLayouts: () => Promise<Layout[]>;
    getActiveLayout: () => Promise<Layout>;
    setActiveLayout: (layout: Layout) => Promise<void>;
    updateUserLayout: (layout: Layout) => Promise<Layout>;
    createUserLayout: (layout: Layout) => Promise<Layout>;
    deleteUserLayout: (layout: Layout) => Promise<void>;
}

const UserSettingsContext = createContext<UserSettingsContextType>({
    settings: null,
    getSettingsStore: () => null,
    setSettingsStore: () => null,
    markSwingAsFavorite: () => null,
    getCorridors: () => null,
    getLayouts: () => Promise.resolve([]),
    getActiveLayout: () => Promise.resolve({} as Layout),
    setActiveLayout: () => Promise.resolve(),
    updateUserLayout: (layout: Layout) => Promise.resolve(layout),
    createUserLayout: (layout: Layout) => Promise.resolve(layout),
    deleteUserLayout: () => Promise.resolve(),
});

function UserSettingsProvider({ children, store }: UserSettingsProviderProps) {
    const { user, supabaseClient } = useSession();
    const { updateSwing } = useActivityContext();
    const queryClient = useQueryClient();

    const markSwingAsFavorite = async (swing: FavoriteSwing) => {
        const isFavorite = !!swing.name;
        // Reflect in database
        const { error } = await supabaseClient
            .from('swings')
            .update({
                favorite: isFavorite,
                name: swing.name,
            })
            .eq('id', swing.id);
        if (error) {
            console.error('Error updating swing favorite', error);
            return;
        }

        // Invalidate local cache
        queryClient.invalidateQueries({ queryKey: ['favoriteSwings'] });

        updateSwing({
            ...swing,
            isFavorite,
        });
    };

    const getUINodeTree = async () => {
        const uiNodesTree = await getAllUINodes(supabaseClient);

        store.actions.setUINodeTree(uiNodesTree);

        return uiNodesTree;
    };

    const getLayouts = async () => {
        if (!user) return [];

        const layouts = await getUserLayouts(supabaseClient, user.id);
        store.actions.setLayouts(layouts);
        store.layouts = layouts; // setLayouts doesn't actually set layouts *facepalm*

        return layouts;
    };

    // This method is an internal helper method to keep the layouts list up to date after minor changes
    // This eliminates the need to refetch the layouts list from the database every time a layout is updated
    const updateLayoutsList = ({ newLayout, oldLayout }: { newLayout?: Layout; oldLayout?: Layout }) => {
        const layouts = [...(store.layouts || [])];
        if (newLayout) {
            const index = _.findIndex(layouts, (x) => x.id === newLayout.id);
            if (index < 0) layouts.push(newLayout);
            else layouts[index] = newLayout;
        }
        if (oldLayout && oldLayout.id !== newLayout?.id) _.remove(layouts, (x) => x.id === oldLayout.id);

        store.actions.setLayouts(layouts);
        store.layouts = layouts; // setLayouts doesn't actually set layouts *facepalm*
    };

    const getActiveLayout = async () => {
        if (!user) return {} as Layout;

        const activeLayoutID = await getUserActiveLayoutID(supabaseClient, user.id);
        const layout = await getUserLayout(supabaseClient, user.id, activeLayoutID);

        updateLayoutsList({ newLayout: layout });
        store.actions.setActiveLayout(_.cloneDeep(layout));

        return layout;
    };

    const setActiveLayout = async (layout: Layout) => {
        if (!user) return;

        const activeLayoutID = await setUserActiveLayoutID(supabaseClient, user.id, layout.id);
        const activeLayout =
            layout.id === activeLayoutID ? layout : await getUserLayout(supabaseClient, user.id, activeLayoutID);

        if (layout !== activeLayout) updateLayoutsList({ newLayout: activeLayout });
        store.actions.setActiveLayout(activeLayout);
        store.activeLayout = activeLayout; // setActiveLayout doesn't actually set activeLayout *facepalm*
    };

    const handleUpdateUserLayout = async (layout: Layout) => {
        if (!user) return layout;

        const activeLayoutID = store.activeLayout?.id;
        let savedLayout;
        if (layout.id > 0) {
            savedLayout = user ? await updateUserLayout(supabaseClient, user.id, layout.id, layout) : layout;
        } else {
            savedLayout = user ? await createUserLayout(supabaseClient, user.id, layout) : layout;
        }

        updateLayoutsList({ newLayout: savedLayout });

        // set the active layout to the saved layout
        // if it was the active layout before
        if (activeLayoutID === savedLayout.id) store.actions.setActiveLayout(_.cloneDeep(savedLayout));
        else await getActiveLayout();

        return savedLayout;
    };

    const handleCreateUserLayout = async (layout: Layout) => {
        if (!user) return layout;

        const savedLayout = user ? await createUserLayout(supabaseClient, user.id, layout) : layout;

        updateLayoutsList({ newLayout: savedLayout });

        return savedLayout;
    };

    const handleDeleteUserLayout = async (layout: Layout) => {
        if (!user || !store.layouts || store.layouts.length <= 1) return;

        const activeLayoutID = store.activeLayout?.id;
        await deleteUserLayout(supabaseClient, user.id, layout.id);

        updateLayoutsList({ oldLayout: layout });

        // if the active layout was deleted, set the active layout to the first layout in the list
        if (activeLayoutID === layout.id) await setActiveLayout(store.layouts[0]);
    };

    const getCorridors = async () => {
        const corridors = await getAllCorridors();
        if (!corridors) return [];

        // this feels wrong
        useGlobalStore.setState({ corridors });
        store.actions.setActiveCorridor(corridors[0]);

        return corridors;
    };

    const getSettingsStore = async () => {
        // Fetch user tags and write to store
        const availableTags = await getUsersTags(supabaseClient);
        store.actions.setAvailableTags(availableTags);

        // Fetch user settings store
        const userSettingsStore = await getUserSettingsStore(supabaseClient);
        store.actions.setUserSettings(userSettingsStore?.userSettings ?? {});
    };

    const setSettingsStore = async (userSettingsStore: UserSettingsStore) => {
        await supabaseClient.from('user_settings').upsert({ user_id: user?.id, settings: userSettingsStore });
    };

    // Getting all initial data that the client needs.
    useEffect(() => {
        if (user) {
            getSettingsStore();
        }
        getUINodeTree();
        getCorridors();
        getLayouts();
        getActiveLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <UserSettingsContext.Provider
            value={{
                settings: store,
                getSettingsStore,
                setSettingsStore,
                markSwingAsFavorite,
                getCorridors,
                getLayouts,
                getActiveLayout,
                setActiveLayout,
                updateUserLayout: handleUpdateUserLayout,
                createUserLayout: handleCreateUserLayout,
                deleteUserLayout: handleDeleteUserLayout,
            }}
        >
            {children}
        </UserSettingsContext.Provider>
    );
}

export { UserSettingsProvider, UserSettingsContext };
