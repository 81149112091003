import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router';

import BoothApp from './app/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { Button, typography, VStack } from '@ui';
import { errorStyle } from './main.css';

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: 'https://6af4068cde85ec4f006d960f0f4fe63d@o4508777394208768.ingest.de.sentry.io/4508777395585104',
        integrations: [],
    });
}

const queryClient = new QueryClient();

const ErrorFallback = ({
    error,
    resetError,
}: {
    error: unknown;
    componentStack: string;
    eventId: string;
    resetError(): void;
}) => {
    return (
        <div role="alert" className={errorStyle}>
            <VStack spacing={16} center>
                <h2 className={typography({ variant: 'h2' })}>Something went wrong</h2>
                <p className={typography({ variant: 'p' })}>We're sorry for the inconvenience. Please try again.</p>
                <Button variant="primary" onClick={resetError}>
                    Reload
                </Button>
            </VStack>
        </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Sentry.ErrorBoundary fallback={ErrorFallback} onReset={() => window.location.reload()}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <BoothApp />
            </BrowserRouter>
        </QueryClientProvider>
    </Sentry.ErrorBoundary>,
);
