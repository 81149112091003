import { useContext, useState } from 'react';
import _ from 'lodash';

import {
    localize,
    isType,
    unitConversionMap,
    DEFAULT_KEY_PARAMETER_ID,
    type Nil,
    type Unit,
    type Swing,
    type UIParameter,
} from '@core';
import { ActivityNavigation, ActivitySidebar } from '@ui';
import { ImplementationOf } from '../../UIComponent.types';
import { setComparisonSwingByID, useGlobalStore } from '../../../../state/globalStore';
import { UserSettingsContext } from '../../../../utils/contexts/UserSettingsContext';
import SwingNameModal from '../../../modals/SwingNameModal';

import * as css from './ActivityNavigation.css';
import { useActivityContext } from '../../../../utils/contexts/ActivityContext';

export const ActivityNavigationImplementation: ImplementationOf<'activity_navigation'> = ({
    currentDevice,
    children,
}) => {
    const parameterNode = _(children)
        .map((x) => x.currentNode)
        .find((x) => isType(x, 'parameter')) as UIParameter | undefined;

    const parameterID = parameterNode?.parameter?.id ?? DEFAULT_KEY_PARAMETER_ID;

    const parameterName = parameterNode?.name?.value ?? _.startCase(parameterID); // TODO: Localize

    const parameterUnit = parameterNode?.parameter?.unit ?? 'm';

    const props: Props = {
        parameterName,
        parameterID,
        parameterUnit,
    };

    switch (currentDevice) {
        case 'kiosk':
            return <Kiosk {...props} />;
        case 'floor':
            return <Floor {...props} />;
        default:
            return <div />;
    }
};

const getDisplayValue = (parameterID: string, parameterUnit: Unit, swing: Swing | Nil) => {
    const parameter =
        swing?.fullAnalysis?.data?.analysis?.parameter_values?.[parameterID] ??
        swing?.quickAnalysis?.data?.analysis?.parameter_values?.[parameterID];

    if (parameter) {
        const p = unitConversionMap[parameter.unit](parameter.value);
        return `${p.value}${p.symbol}`;
    }

    return '— ' + unitConversionMap[parameterUnit](undefined).symbol;
};

interface EnhancedSwing extends Swing {
    value: string;
    number: number;
    original: Swing;
}

const enhanceSwings = (parameterID: string, parameterUnit: Unit, swings: Swing[]): EnhancedSwing[] =>
    _(swings)
        .compact() // shallow copy with nil values removed
        .map((swing, index, list) => ({
            // `Swing` enhanced with value and number.
            value: getDisplayValue(parameterID, parameterUnit, swing),
            number: list.length - index,

            // rest of the properties of the swing
            ...swing,

            // ... and then the original Swing object (for assigning to comparison swing)
            original: swing,
        }))
        .value();

interface Props {
    readonly parameterName: string;
    readonly parameterID: string;
    readonly parameterUnit: Unit;
}

function Kiosk({ parameterName, parameterID, parameterUnit }: Props) {
    const { swings } = useActivityContext();
    const enhancedSwings = enhanceSwings(parameterID, parameterUnit, swings);
    const { markSwingAsFavorite } = useContext(UserSettingsContext);
    const [showSwingNameModal, setShowSwingNameModal] = useState(false);
    const [tempSwingId, setTempSwingId] = useState<string | null>(null);
    const [activeSwingId, comparisonSwingId] = useGlobalStore((state) => [
        state.activeSwingID,
        state.comparisonSwingID,
    ]);

    const onToggleComparison = (val: boolean, uuid: string) => {
        return setComparisonSwingByID(val ? uuid : null);
    };

    const onToggleFavorite = (val: boolean, uuid: string) => {
        if (val) {
            setTempSwingId(uuid);
            setShowSwingNameModal(true);
        } else {
            markSwingAsFavorite({ uuid, name: null });
        }
    };

    const onSwingNameSubmit = (name: string) => {
        if (tempSwingId) {
            markSwingAsFavorite({ uuid: tempSwingId, name });

            setTempSwingId(null);
        }
    };

    const onSelect = (swing: Swing) => {
        useGlobalStore.setState({
            activeSwingID: swing.uuid,
        });
    };

    return (
        <>
            <div className={css.layout}>
                <ActivityNavigation
                    heading={localize('activity_navigation.header')}
                    onToggleFavorite={onToggleFavorite}
                    onToggleComparison={onToggleComparison}
                    onSelectSwing={onSelect}
                    swings={enhancedSwings}
                    selectedKeyParameter={parameterName}
                    selectedSwingId={activeSwingId}
                    comparisonSwingId={comparisonSwingId}
                    debug={false}
                />
            </div>
            <SwingNameModal
                isOpen={showSwingNameModal}
                setIsOpen={(val) => setShowSwingNameModal(val)}
                onSubmit={(name) => onSwingNameSubmit(name)}
            />
        </>
    );
}

function Floor({ parameterName, parameterID, parameterUnit }: Props) {
    const { swings } = useActivityContext();
    const defaultDisplayValue = getDisplayValue(parameterID, parameterUnit, null);
    const enhancedSwings = enhanceSwings(parameterID, parameterUnit, swings);

    const isAnalyzing = useGlobalStore((state) => state.isAnalyzing);

    return (
        <ActivitySidebar
            swings={enhancedSwings}
            isAnalyzing={isAnalyzing}
            fallbackDisplayValue={defaultDisplayValue}
            selectedKeyParameter={parameterName}
        />
    );
}
