import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { CaretRight as CaretRightIcon } from '@phosphor-icons/react';

import {
    localize,
    ALL_DEVICES,
    Axis,
    inferSwingPosition,
    isType,
    Layout,
    Nil,
    rhsOfLast,
    setToggle,
    SwingPosition,
    swingPositionToPositionNumber,
    UIGraphLine,
    UIModule,
    UIParameter,
    UISwingFoundations,
    UINodeRelation,
    UINodeToggler,
    UINodeTree,
    AnyCategoryID,
    getCategorizations,
    filterByCategory,
    getLocalizedSwingPositionName,
    SWING_POSITIONS,
} from '@core';
import { Parameter } from '../../../components/UIComponents/implementations/Parameter/Parameter';
import { Button, typography, ToggleButton, Panel, Modal, colors, ToolPanel } from '@ui';

import * as css from './CustomModuleEditor.css';

//! REFACTOR functionality shared between SwingFoundationsEditor, CustomModuleEditor and KeyParameterEditor

interface CustomModuleEditorProps {
    layout: Layout;
    uiNodeTree: UINodeTree | Nil;
    moduleBeingEdited: UIModule | Nil;
    onClose: () => void;
}

export function CustomModuleEditor({ layout, uiNodeTree, moduleBeingEdited, onClose }: CustomModuleEditorProps) {
    const customModuleID = moduleBeingEdited?.id;
    const timeSeriesGraphs = _(uiNodeTree?.nodes ?? [])
        // filter out non-time series graphs
        .filter((node) => node?.type === 'graph' && node.categories.graph_type !== 'graph_type.key')
        // map to node and first child graph line
        .map((graph) => ({
            graph,
            childID: _.find(
                uiNodeTree?.relations,
                (r) => r.parent_ui_node_id === graph.id && r.child_ui_node_id.startsWith('graph_line.'),
            )?.child_ui_node_id,
        }))
        .filter(({ childID }) => !!childID)
        // map to time series of graph_line nodes
        .map(({ graph, childID }) => {
            const graphLine = _.find(uiNodeTree?.nodes, (n) => n.id === childID) as UIGraphLine;
            return {
                graph,
                timeSeriesID: isType(graphLine, 'graph_line') ? graphLine?.time_series?.id : null,
            };
        })
        .filter(({ timeSeriesID }) => !!timeSeriesID)
        .value();

    const swingFoundationsGroups = useMemo(() => {
        const swingFoundationNodes = _(uiNodeTree?.nodes ?? [])
            .filter((c) => c.type === 'swing_foundations')
            .keyBy((n) => inferSwingPosition(n.id) || 'all')
            .value() as { [key in SwingPosition | 'all']: UISwingFoundations };

        const parameters = _(uiNodeTree?.relations ?? [])
            .concat((layout?.customizations as UINodeRelation[]) ?? [])
            .filter((r) => _.some(swingFoundationNodes, (n) => n.id === r.parent_ui_node_id))
            .uniqBy((r) => r.child_ui_node_id)
            .map((r) => _.find(uiNodeTree?.nodes, (n) => n.id === r.child_ui_node_id))
            .filter((n) => n?.type === 'parameter')
            .map((node) => {
                const position = inferSwingPosition(node?.id) ?? 'p1';

                const togglers = _([swingFoundationNodes[position], swingFoundationNodes.all])
                    .compact()
                    .map(
                        (parentNode) =>
                            ({
                                layout,
                                parentNode,
                                childNode: node,
                                currentDevices: [...parentNode.show_on],
                                defaultDevices: [...parentNode.show_on],
                                defaultChildMetadata: {},
                            }) as UINodeToggler,
                    )
                    .value();

                return togglers.length ? ({ ...node, position } as UIParameter & { position: string }) : null;
            })
            .compact()
            .value();

        const positionGroups = _(parameters)
            .groupBy((p) => p.position)
            .map((positionParameters, position) => ({
                position,
                positionNumber: swingPositionToPositionNumber(position as SwingPosition),
                parameters: _(positionParameters)
                    .sortBy((p) => p.name?.value)
                    .value(),
            }))
            .sortBy((p) => p.positionNumber)
            .value();

        return positionGroups;
    }, [layout.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCategorizedParameters = (parameters: UIParameter[], groupBy: keyof UIParameter['categories']) =>
        _(parameters)
            .groupBy((node) => node.categories[groupBy])
            .map((params, categoryID) => ({
                category: _.find(uiNodeTree?.categories, (c) => c.id === categoryID) || null,
                parameters: params,
            }))
            .sortBy((group) => group.category?.display_order ?? Infinity)
            .value();

    const parameterNodes = _.filter(uiNodeTree?.nodes ?? [], (node) => node.type === 'parameter') as UIParameter[];

    const timeSeriesParametersByCategory = _(timeSeriesGraphs)
        .map(({ graph, timeSeriesID }) => {
            return {
                graph,
                axes: _(parameterNodes)
                    .filter((p) => p.parameter.time_series_id === timeSeriesID)
                    .sortBy((p) => inferSwingPosition(p.id))
                    .groupBy((p) => p.parameter.axis || 'x')
                    .value() as Record<Axis, UIParameter[]>,
            };
        })
        .groupBy(
            ({ axes }) =>
                _(axes)
                    .values()
                    .flatten()
                    .map((p) => p.categories.metric)
                    .find((x) => !!x) || 'other',
        )
        .map((timeSeries, categoryID) => ({ categoryID, timeSeries }))
        .sortBy(
            ({ categoryID }) => _.find(uiNodeTree?.categories, (c) => c.id === categoryID)?.display_order ?? Infinity,
        )
        .value();

    const orderedCategoryIDs = _(uiNodeTree?.categories)
        // .filter(c => c.id.startsWith('body_part.') || c.id.startsWith('swing_foundation_group.'))
        // .sortBy(c => [c.id.startsWith('body_part'), c.display_order])
        .filter((c) => c.id.startsWith('body_part.'))
        .sortBy((c) => c.display_order)
        .map((c) => c.id)
        .value() as AnyCategoryID[];

    const [categoryFilter, setCategoryFilter] = useState<Set<AnyCategoryID>>(new Set());
    const categorizations = useMemo(() => {
        const map = uiNodeTree && getCategorizations(uiNodeTree);
        if (!map) return null;
        //! HACK, use categoryIDs already assigned to graphs and assign them to parameters in the same time series
        for (const parameterNode of parameterNodes) {
            const timeSeriesID = parameterNode.parameter.time_series_id;
            const graph = _.find(timeSeriesGraphs, (g) => g.timeSeriesID === timeSeriesID)?.graph;
            const graphCategories = graph?.id && map[graph.id];
            if (graphCategories) (map[parameterNode.id] || []).push(...graphCategories);
        }
        return map;
    }, [uiNodeTree, parameterNodes, timeSeriesGraphs]);
    const visibleUINodeIDs = useMemo(
        () =>
            (categorizations && categoryFilter && filterByCategory(categorizations, categoryFilter)) ||
            'all_is_visible',
        [categorizations, categoryFilter],
    );

    const [selectedParameterIDs, setSelectedParameterIDs] = useState<Set<string>>(new Set());
    useEffect(
        () => {
            setSelectedParameterIDs(
                new Set(
                    (customModuleID &&
                        _(layout.customizations)
                            .filter((c) => c.parent_ui_node_id === customModuleID)
                            .map((c) => c.child_ui_node_id)
                            .value()) ||
                        [],
                ),
            );
            setCategoryFilter(new Set());
        },
        [customModuleID], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const hasSelectedParameters = selectedParameterIDs.size > 0;
    const showSwingFoundations = uiNodeTree && categoryFilter.size === 0;

    if (!moduleBeingEdited) return null;

    return (
        <Modal
            title="Edit Custom Module"
            onDismiss={onClose}
            footer={{
                primary: (
                    <Button
                        variant="primary"
                        isDisabled={false}
                        onClick={() => {
                            if (!customModuleID) return;
                            const customModuleNumber = rhsOfLast(customModuleID, '_');
                            const title = `My Custom Module #${Number(customModuleNumber)}`;

                            const rootToggler: UINodeToggler = {
                                layout,
                                parentNode: null,
                                childNode: moduleBeingEdited,
                                currentDevices: [],
                                defaultChildMetadata: {
                                    title,
                                },
                            };

                            const oldChildTogglers = _(layout.customizations)
                                .filter((c) => c.parent_ui_node_id === customModuleID)
                                .map(
                                    (c) =>
                                        ({
                                            layout,
                                            parentNode: moduleBeingEdited,
                                            childNode: _.find(uiNodeTree?.nodes, (n) => n.id === c.child_ui_node_id),
                                            currentDevices: ALL_DEVICES,
                                        }) as UINodeToggler,
                                )
                                .value();

                            for (const oldChild of oldChildTogglers) setToggle(oldChild, false);

                            const newChildTogglers = _.map(
                                [...selectedParameterIDs],
                                (id) =>
                                    ({
                                        layout,
                                        parentNode: moduleBeingEdited,
                                        childNode: _.find(uiNodeTree?.nodes, (n) => n.id === id),
                                        currentDevices: ALL_DEVICES,
                                    }) as UINodeToggler,
                            );

                            setToggle(rootToggler, true);
                            for (const newChild of newChildTogglers) setToggle(newChild, true);

                            onClose();
                        }}
                    >
                        {localize('layout_editor.custom_module.save_button')}
                    </Button>
                ),
                secondary: (
                    <Button variant="secondary" onClick={onClose}>
                        {localize('layout_editor.custom_module.cancel_button')}
                    </Button>
                ),
            }}
        >
            <div className={css.root}>
                <aside className={css.aside}>
                    <ToolPanel title="Filter">
                        <div className={css.filterGroup}>
                            <h4 className={typography({ variant: 'h4' })}>Body Parts</h4>
                            {_.map(orderedCategoryIDs, (categoryID) => {
                                const title =
                                    _.find(uiNodeTree?.categories, (c) => c.id === categoryID)?.name?.value ||
                                    _.startCase(categoryID);
                                return (
                                    <ToggleButton
                                        key={categoryID}
                                        icon="chart-line"
                                        maxLetters={36}
                                        isActive={categoryFilter.has(categoryID)}
                                        onChange={(isActive) => {
                                            if (isActive) {
                                                setCategoryFilter((prevFilter) => new Set(prevFilter).add(categoryID));
                                            } else {
                                                setCategoryFilter((prevFilter) => {
                                                    const newFilter = new Set(prevFilter);
                                                    newFilter.delete(categoryID);
                                                    return newFilter;
                                                });
                                            }
                                        }}
                                    >
                                        {title}
                                    </ToggleButton>
                                );
                            })}
                        </div>
                    </ToolPanel>
                </aside>
                <div className={css.main}>
                    <Panel
                        title="Selected Parameters"
                        collapsible={hasSelectedParameters}
                        countChildren
                        initialState={hasSelectedParameters ? 'open' : 'closed'}
                    >
                        {_.map([...selectedParameterIDs], (parameterID) => {
                            const node = _(uiNodeTree?.nodes).find((n) => n.id === parameterID) as UIParameter;

                            if (!node) {
                                return null;
                            }

                            return (
                                uiNodeTree && (
                                    <Parameter
                                        key={node.id}
                                        node={node as UIParameter}
                                        currentDevice="kiosk"
                                        children={[]}
                                        uiNodeTree={uiNodeTree}
                                        onClick={() => {
                                            setSelectedParameterIDs((prev) => {
                                                const newSet = new Set(prev);
                                                newSet.delete(parameterID);
                                                return newSet;
                                            });
                                        }}
                                        isSelected={true}
                                    />
                                )
                            );
                        })}
                    </Panel>
                    {showSwingFoundations && (
                        <Panel
                            key="swing_foundations"
                            title={localize('layout_editor.panel.swing_foundations')}
                            initialState="open"
                            collapsible={false}
                        >
                            {_.map(swingFoundationsGroups, ({ position, parameters }) => {
                                const title = getLocalizedSwingPositionName(position as SwingPosition);
                                return (
                                    <Panel key={position} icon="lego" title={title} initialState="closed" collapsible>
                                        <div className={css.parameters}>
                                            {_.map(
                                                getCategorizedParameters(parameters, 'swing_foundation_group'),
                                                ({ category, parameters: categorizedParams }) => (
                                                    <div key={category?.id || 'other'} className={css.group}>
                                                        <h4 className={typography({ variant: 'h3' })}>
                                                            {category?.name?.value || 'Other'}
                                                        </h4>

                                                        <div className={css.parameters}>
                                                            {_.map(
                                                                categorizedParams,
                                                                (node) =>
                                                                    uiNodeTree &&
                                                                    (visibleUINodeIDs === 'all_is_visible' ||
                                                                        visibleUINodeIDs.has(node.id)) && (
                                                                        <Parameter
                                                                            key={node.id}
                                                                            node={node as UIParameter}
                                                                            currentDevice="kiosk"
                                                                            children={[]}
                                                                            uiNodeTree={uiNodeTree}
                                                                            onClick={() => {
                                                                                setSelectedParameterIDs((prev) => {
                                                                                    const newSet = new Set(prev);
                                                                                    const exists = newSet.has(node.id);
                                                                                    if (exists) {
                                                                                        newSet.delete(node.id);
                                                                                    } else {
                                                                                        newSet.add(node.id);
                                                                                    }
                                                                                    return newSet;
                                                                                });
                                                                            }}
                                                                            isSelected={selectedParameterIDs.has(
                                                                                node.id,
                                                                            )}
                                                                        />
                                                                    ),
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </Panel>
                                );
                            })}
                        </Panel>
                    )}

                    {_.map(timeSeriesParametersByCategory, ({ timeSeries, categoryID }) => {
                        return (
                            <Panel
                                key={categoryID}
                                title={
                                    _.find(uiNodeTree?.categories, (c) => c.id === categoryID)?.name?.value ||
                                    _.startCase(categoryID)
                                }
                                countChildren={false}
                                collapsible={true}
                                initialState="open"
                            >
                                {_.map(timeSeries, ({ graph, axes }) => {
                                    if (visibleUINodeIDs !== 'all_is_visible' && !visibleUINodeIDs.has(graph.id))
                                        return null;

                                    const positionsPerAxis = _.mapValues(axes, (axis) =>
                                        _.keyBy(axis, (p) => inferSwingPosition(p.id)),
                                    );

                                    const handleAdd = (parameterID: string) => {
                                        setSelectedParameterIDs((prev) => {
                                            if (!parameterID) {
                                                return prev;
                                            }

                                            const newSet = new Set(prev);
                                            newSet.add(parameterID);
                                            return newSet;
                                        });
                                    };

                                    const name = graph.name?.value ?? graph.short_name?.value ?? _.startCase(graph.id);

                                    return (
                                        <ParameterSelector
                                            key={name}
                                            name={name}
                                            positionsPerAxis={
                                                positionsPerAxis as Record<Axis, Record<SwingPosition, UIParameter>>
                                            }
                                            onAdd={handleAdd}
                                        />
                                    );
                                })}
                            </Panel>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}

interface ParameterSelectorState {
    position: SwingPosition | null;
    axis: Axis | null;
    parameterID: string | null;
}

// TODO: Move to a different file
function ParameterSelector({
    name,
    positionsPerAxis,
    onAdd = () => {},
}: {
    positionsPerAxis: Record<Axis, Record<SwingPosition, UIParameter>>;
    name: string;
    onAdd?: (parameterID: string) => void;
}) {
    const axes = _.keys(positionsPerAxis) as Axis[];
    const defaultAxis = axes.length > 1 ? null : axes[0];
    const [state, setState] = useState<ParameterSelectorState>({
        position: null,
        axis: null,
        parameterID: null,
    });

    const updateState = (updates: Partial<ParameterSelectorState>) => {
        const newState = {
            ...state,
            ...updates,
        };

        const axis = newState.axis || defaultAxis;
        newState.parameterID = axis && newState.position ? positionsPerAxis?.[axis]?.[newState.position]?.id : null;

        setState(newState);
    };

    const handleAdd = () => {
        if (state.parameterID) {
            onAdd(state.parameterID);
            setState({
                position: null,
                axis: null,
                parameterID: null,
            });
        }
    };

    return (
        <div className={css.parameterSelectorLayout}>
            <div className={css.parameterSelector}>
                <div className={css.parameterSelectorTitle}>
                    <h3 className={typography({ variant: 'h3' })}>{name}</h3>
                </div>
                <div className={css.parameterSelectorButtons}>
                    {_.map(SWING_POSITIONS, (position) => {
                        const isActive = state.position === position;
                        const isExpanded = isActive && !defaultAxis;
                        return (
                            <div key={position} className={css.toggleGroup({ isActive })}>
                                <ToggleButton
                                    key="position-toggle"
                                    variant="darker"
                                    isActive={isActive}
                                    onChange={(isActive) => {
                                        updateState({
                                            position: isActive ? position : null,
                                        });
                                    }}
                                >
                                    {position}
                                </ToggleButton>
                                {isExpanded && <CaretRightIcon weight="regular" color={colors.bluegray[600]} />}
                                {isExpanded &&
                                    _.map(axes, (axis) => (
                                        <ToggleButton
                                            key={axis}
                                            variant="darker"
                                            isActive={state.axis === axis}
                                            onChange={(isActiveAxis) => {
                                                updateState({
                                                    axis: isActiveAxis ? axis : null,
                                                });
                                            }}
                                        >
                                            {axis}
                                        </ToggleButton>
                                    ))}
                            </div>
                        );
                    })}
                </div>
            </div>

            <Button variant="secondary" size="small" isDisabled={!state.parameterID} onClick={handleAdd}>
                {localize('layout_editor.custom_module.add_button')}
            </Button>
        </div>
    );
}
