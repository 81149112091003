import { ComponentPropsWithoutRef, CSSProperties, ReactNode } from 'react';
import * as css from './VStack.css';
import classNames from 'classnames';
import { FlexAlignment, FlexDirection, FlexSpacing } from '@core';
import _ from 'lodash';

/*
 * The VStack Component
 * ====================
 *
 * The VStack (Vertical Stack) component is designed to layout its child components
 * vertically with a specified gap between each child. This is particularly useful for
 * creating consistent vertical spacing in your UI without having to individually manage
 * the margins of each element.
 *
 * ASCII Art Illustration
 * ----------------------
 *
 * With VStack and a spacing of 10px:
 *
 * [Component 1]
 *      ↓ 10px
 * [Component 2]
 *      ↓ 10px
 * [Component 3]
 *
 * The gap between the components is controlled by the `spacing` prop.
 *
 * Example Usage
 * -------------
 *
 * Here's how you might use the VStack component in your application to layout three buttons
 * with a 20px gap between each button:
 *
 * <VStack spacing={20}>
 *   <button>Button 1</button>
 *   <button>Button 2</button>
 *   <button>Button 3</button>
 * </VStack>
 *
 */

interface VStackProps extends ComponentPropsWithoutRef<'div'> {
    children: ReactNode;
    /** shorthand for alignment: 'center' */
    center?: boolean;
    spacing?: FlexSpacing; // The gap between elements
    alignment?: FlexAlignment;
    direction?: FlexDirection;
}

export function VStack({ children, center, spacing, alignment, direction, style, className, ...props }: VStackProps) {
    // Merge dynamicStyle with any styles passed in through props
    const mergedStyles: CSSProperties = {
        ...style, // Apply props style first, so dynamic styles can override if necessary
        gap: _.isFinite(spacing) // is a number?
            ? spacing + 'px'
            : spacing ?? '10px',
        alignItems: center ? 'center' : alignment ?? undefined,
        flexDirection: direction === 'reverse' ? 'column-reverse' : undefined,
    };

    return (
        <div className={classNames([css.container, className])} style={mergedStyles} {...props}>
            {children}
        </div>
    );
}
