import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Screen, Button } from '@ui';

import { useActivityContext } from '../../utils/contexts/ActivityContext/ActivityContext';
import DataManager from '../../DataManager/DataManager';
import { ActivityFilter, Filter } from '../../components/ActivityFilter/ActivityFilter';
import { ActivityList } from '../../components/ActivityList/ActivityList';

import * as css from './ActivityOverview.css';

const ACTIVITIES_PAGE_SIZE = 20;

export function ActivityOverview() {
    const { currentActivityId, swings } = useActivityContext();
    const navigate = useNavigate();
    const {
        data: activitiesPages,
        isLoading: isLoadingActivities,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['activities'],
        queryFn: ({ pageParam = 0 }) =>
            DataManager.getInstance().fetchAllUserActivities(pageParam, ACTIVITIES_PAGE_SIZE),
        getNextPageParam: (lastPage) => {
            if (!lastPage) {
                return undefined;
            }

            const pageData = lastPage as unknown[];
            return pageData.length === 0 ? undefined : pageData.length;
        },
        initialPageParam: 0,
    });

    const activities = activitiesPages?.pages.flat() || [];

    // Add swings to current activity
    const currentActivity = activities?.find((activity) => activity.id === currentActivityId);
    if (currentActivity) {
        currentActivity.swing_count = swings.length;
    }

    const showNoResultsMessage = !activities || activities?.length <= 0;

    const handleClose = () => {
        navigate('../');
    };

    const setFilter = (filter: Filter) => {
        // TODO(at): Implement
        // // TODO(at): Filter in the db
        // // Filters by date and accounts for different timezones
        // const dateRangeActivities = activities?.filter((activity) => {
        //     const parsedDate = new Date(activity.start_time);
        //     const activityDate = new Date(
        //         Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0, 0),
        //     );
        //     return (!startDate || activityDate >= startDate) && (!endDate || activityDate <= endDate);
        // });
        // // TODO(at): Filter in the db
        // // Filters by search
        // const filteredActivities =
        //     dateRangeActivities?.filter((activity) => {
        //         const hasFavorites = favoritesOn ? (activity?.favorite_swings_count ?? 0) > 0 : true;
        //         const matchesClubFilter = clubFilter ? activity.club_id === clubFilter.id : true;
        //         const matchesFilter =
        //             filter.length > 0
        //                 ? activity?.tags?.some((tag) => tag.name?.toLowerCase().includes(filter?.toLowerCase()))
        //                 : true;
        //         return hasFavorites && matchesClubFilter && matchesFilter;
        //     }) || []; // Default to an empty array if undefined
    };

    return (
        <Screen.Root
            title="Activity overview"
            header={{
                static: {
                    end: (
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    ),
                },
            }}
            fixed
        >
            <Screen.Column span="6/20" sticky>
                <div className={css.sidebar}>
                    <ActivityFilter numberOfActivities={activities?.length} onFilterChange={setFilter} />
                </div>
            </Screen.Column>
            <Screen.Column span="14/20">
                <div className={css.activities}>
                    <ActivityList
                        isLoading={isLoadingActivities}
                        showNoResultsMessage={showNoResultsMessage}
                        activities={activities}
                        currentActivityId={currentActivityId}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                    />
                </div>
            </Screen.Column>
        </Screen.Root>
    );
}
