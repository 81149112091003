// NOTE: This is the Beast "Communication" Websocket handler.
// It handles sending and receiving status messages from the Beast.
// e.g.
// SWING_VIDEOS_AVAILABLE
// ANALYSIS_AVAILABLE
// ANALYSIS_STATE
// BEAST_STATUS
// CURRENT_ACTIVITY_ID

import useWebSocket from 'react-use-websocket';
import { useGlobalStore } from '../state/globalStore/globalStore';
import { BeastMessage, BeastPayload } from './BeastListener.types';
import { CommunicationMessage } from '@core';
import { EDeviceType } from '../utils/types/environment';

export type BeastSocketProps = {
    serverUrl: string;
    onMessage: (data: BeastPayload) => void;
};

export type BeastSocket = {
    sendMessage: (message: BeastMessage) => void;
    sendActivityId: (activityId: number | null) => void;
};

export const useBeastSocket = ({ serverUrl, onMessage }: BeastSocketProps): BeastSocket => {
    const onOpen = () => {
        useGlobalStore.setState({ isConnectedToBeast: true });
    };

    const onClose = () => {
        sendActivityId(null);
        console.log('Disconnecting from beast');
        useGlobalStore.setState({ isConnectedToBeast: false });
    };

    const messageHandler = (message: MessageEvent) => {
        if (message.type === 'message' && typeof onMessage === 'function') {
            try {
                const data = JSON.parse(message.data);
                onMessage(data);
            } catch (e) {
                console.error('WebSocket data parsing error:', e);
                return;
            }
        }
    };

    function sendActivityId(activityId: number | null) {
        const isFloor = import.meta.env['VITE_PUBLIC_DEVICE_TYPE'] === EDeviceType.FLOOR;
        const messageType = isFloor ? CommunicationMessage.FLOOR_STATUS : CommunicationMessage.KIOSK_STATUS;

        const id = activityId !== null && activityId > 0 ? activityId : null;
        sendJsonMessage({
            type: messageType,
            content: { activity_id: id },
        });
    }

    const isSecureSockets = import.meta.env['VITE_PUBLIC_UNSECURE_SOCKETS'] !== 'true';
    const socketPrefix = isSecureSockets ? 'wss://' : 'ws://';
    const { sendJsonMessage } = useWebSocket(`${socketPrefix}${serverUrl}`, {
        onOpen: onOpen,
        onClose: onClose,
        onMessage: messageHandler,
        shouldReconnect: () => true,
        reconnectAttempts: 1000, // ~ 30 minute reconnection window
        reconnectInterval: 2000, // Try reconnecting every two secs
    });

    return { sendMessage: sendJsonMessage, sendActivityId };
};
