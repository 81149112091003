import { formatTime, formatDate, type Activity } from '@core';
import { Link } from 'react-router';
import { typography } from '@ui';
import { Clock } from '@phosphor-icons/react';

import * as css from './ActivityListItem.css';

export type ActivityListItemProps = {
    activity: Activity;
    index: number;
    starredSwingsCount: number;
    noOfSwings: number;
    isCurrentActivity?: boolean;
};

export const ActivityListItem = ({
    isCurrentActivity = false,
    activity,
    index,
    starredSwingsCount,
    noOfSwings,
}: ActivityListItemProps) => {
    return (
        <Link
            key={activity.id}
            className={`${css.activity} ${css.activityItem}`}
            style={{ animationDelay: `${index * 0.03}s` }}
            to={isCurrentActivity ? '/kiosk/current' : `/kiosk/${activity.id}`}
        >
            <div className={css.time}>
                <p
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {formatDate(activity.start_time)}
                </p>
                <Clock width={16} />
                <p
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {formatTime(activity.start_time)}
                </p>
            </div>
            <div className={css.tags}>
                {isCurrentActivity && <div className={css.greenTag}>CURRENT ACTIVITY</div>}
                {activity.club_id && <div className={css.tag}>{activity?.club?.short_name}</div>}
                <div className={css.swingCountTag}>
                    <div className={css.numberOfSwings}>{noOfSwings} swings</div>
                    {/*<div className={css.numberOfStarredSwings}>
                        {starredSwingsCount}
                        <Star />
                    </div>*/}
                </div>
                {activity.tags?.map((tag) => {
                    return (
                        <div key={tag.id} className={css.tag}>
                            {tag.name}
                        </div>
                    );
                })}
            </div>
        </Link>
    );
};
