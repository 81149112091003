import { useEffect, useRef } from 'react';
import DataManager from '../DataManager/DataManager';
import { ANALYSIS_TYPES, IVideo, Analysis, Swing } from '@core';
import { FullAnalysisPayload } from '../DataManager/DataManager.types';
import { transformSkeletonToFramesFirst } from '../utils/skeletonUtils';
import _ from 'lodash';
import { useActivityContext } from '../utils/contexts/ActivityContext';

const CAMERA_NAMES = ['back', 'down_the_line', 'face_on', 'trail_front'];

async function getVideos(swingId: number): Promise<IVideo[]> {
    const dataManager = DataManager.getInstance();
    if (!dataManager.cameraConfig) {
        return [];
    }

    const { storageBaseUrl } = dataManager;

    const getVideoUrl = (cameraId: string): string => {
        return `${storageBaseUrl}/object/public/swings/${swingId}/frontend_recording/${cameraId}.mp4`;
    };

    const getMetadata = async (): Promise<IVideo['metadata']> => {
        const response = await fetch(
            `${storageBaseUrl}/object/public/swings/${swingId}/frontend_recording/metadata.json`,
        );
        const { frame_count, height, width } = await response.json();

        return {
            totalFrames: frame_count,
            sourceWidth: width,
            sourceHeight: height,
        };
    };

    const metadata = await getMetadata();

    return _(dataManager.cameraConfig.camera_id_to_name)
        .toPairs()
        .filter(([, cameraName]) => _.includes(CAMERA_NAMES, cameraName))
        .map(([cameraId, cameraName]) => ({
            name: cameraName,
            url: getVideoUrl(cameraId),
            metadata,
        }))
        .value();
}

async function getAnalysis(swings: Swing[], payload: FullAnalysisPayload): Promise<Analysis | undefined> {
    const id = payload.new.swing_id;
    const status = payload.new.status;
    const typeId = payload.new.type_id;
    const dataManager = DataManager.getInstance();
    const swingUUID = await dataManager.getSwingUUID(id);

    if (status !== 'completed' || !swingUUID) {
        return;
    }

    // We are only interested in completed full analysis data
    if (typeId === ANALYSIS_TYPES['QUICK_ANALYSIS']) {
        return;
    }

    const existingSwingAnalysis = _.find(swings, (swing) => swing.uuid === swingUUID);

    // Bail early if analysis doesn't exist and there's nothing to update.
    if (!existingSwingAnalysis) {
        return;
    }

    // Bail early if we already have this swing's full analysis
    if (existingSwingAnalysis.fullAnalysis) {
        return;
    }

    const analysisId = await dataManager.getAnalysisId(id);

    if (!analysisId) {
        return;
    }

    // At this stage we have a quick analysis that's ready to be upgraded.
    const analysisJsonResponse = await dataManager.getAnalysisJsonDumpById(analysisId);

    if (!analysisJsonResponse) {
        return;
    }

    const { skeleton, bones, measurements, parameter_values, segmentation, images: newImages } = analysisJsonResponse;

    // Keep using existing videos from the Quick Analysis if they exist.
    const existingVideos = existingSwingAnalysis.quickAnalysis?.data.videos;
    // Keep using existing images from the Quick Analysis if they exist.
    const existingImages = existingSwingAnalysis.quickAnalysis?.data.images;

    const frames = transformSkeletonToFramesFirst(skeleton);
    const timestamp = new Date(payload.new.created_at).getTime();
    const videos = existingVideos ?? (await getVideos(id));
    const images = existingImages ?? newImages;

    return {
        uuid: swingUUID,
        data: {
            analysis: {
                timestamp,
                isQuickAnalysis: false,
                frames,
                bones,
                measurements,
                parameter_values,
                segmentation,
                skeleton,
            },
            videos,
            images,
        },
    };
}

export function FullAnalysisListener() {
    const { swings, addAnalysisToSwing } = useActivityContext();
    const latestSwings = useRef(swings);
    const latestAddAnalysis = useRef(addAnalysisToSwing);

    // Avoid unsubscribing the listener on every change
    useEffect(() => {
        latestSwings.current = swings;
        latestAddAnalysis.current = addAnalysisToSwing;
    }, [swings, addAnalysisToSwing]);

    useEffect(() => {
        const handleFullAnalysis = async (payload: FullAnalysisPayload) => {
            const analysis = await getAnalysis(latestSwings.current, payload);
            if (analysis) {
                latestAddAnalysis.current(analysis.uuid, ANALYSIS_TYPES.FULL_ANALYSIS, analysis);
            }
        };

        const subscription = DataManager.getInstance().subscribeToFullAnalysis(handleFullAnalysis);

        return () => {
            subscription?.unsubscribe?.();
        };
    }, []);

    return null;
}
