import { useNavigate } from 'react-router';
import { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { Tabs } from '@ui';
import { PlaybackSettings } from '../../components/VideoPlayback/PlaybackSettings';
import { VideoPlayback } from '../../components/VideoPlayback/VideoPlayback';
import { BackArrowIcon } from '../../components/icons/BackArrow';
import { setComparisonSwingByID, useGlobalStore } from '../../state/globalStore';
import { typography } from '../../styles/typography.css';
import { ComparisonSidebar } from './components/ComparisonSidebar';
import { useVideoPlaybackStore } from '../../components/VideoPlayback/VideoPlayback.state';
import { useActivities } from '../../queries/useActivities';
import DataManager from '../../DataManager/DataManager';
import { useActivityContext } from '../../utils/contexts/ActivityContext';

import * as css from './PlaybackScreen.css';

type PlaybackDisplayOptions = 'playback' | 'comparison';

interface PlaybackProps {
    display?: PlaybackDisplayOptions;
}

export default function Playback({ display }: PlaybackProps) {
    const navigate = useNavigate();
    const [displayMode, setDisplayMode] = useState<PlaybackDisplayOptions>(display ?? 'playback');
    const { activities } = useActivities();
    const { activity: currentActivity, swings } = useActivityContext();

    const activeSwingId = useGlobalStore((state) => state.activeSwingID);
    const comparisonSwingId = useGlobalStore((state) => state.comparisonSwingID);

    const { data: favoriteSwings = [] } = useQuery({
        queryKey: ['favoriteSwings'],
        queryFn: () => DataManager.getInstance().getFavoriteSwings(),
    });

    const comparisonSwing = _.find([...favoriteSwings, ...swings], (s) => s.uuid === comparisonSwingId);

    const activeSwing = useMemo(() => _.find(swings, (s) => s.uuid === activeSwingId), [swings, activeSwingId]);
    const activeSwingAnalysis = useMemo(() => activeSwing?.fullAnalysis ?? activeSwing?.quickAnalysis, [activeSwing]);
    const comparisonSwingAnalysis = useMemo(
        () => comparisonSwing?.fullAnalysis ?? comparisonSwing?.quickAnalysis,
        [comparisonSwing],
    );

    const reset = () => {
        // Reset playback state
        useVideoPlaybackStore.setState({
            autoPlaying: false,
            activeCameraAngle: null,
            playbackSpeed: 1,
        });
    };

    const onBack = () => {
        reset();
        navigate(-1);
    };

    return (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.backNavLeft}>
                    <button onClick={onBack} className={css.arrowButton}>
                        <BackArrowIcon />
                    </button>
                    <p
                        className={typography({
                            size: 'h2',
                            weight: 'bold',
                            color: 'dark',
                        })}
                    >
                        Playback
                    </p>
                </div>
                <Tabs
                    items={['Playback', 'Comparison']}
                    keys={['playback', 'comparison']}
                    activeItem={displayMode}
                    onClick={(activeValue) => {
                        setDisplayMode(activeValue as PlaybackDisplayOptions);
                        reset();
                    }}
                />
            </div>
            <div className={css.container}>
                <div className={css.replay}>
                    {activeSwingAnalysis ? (
                        <VideoPlayback
                            key={activeSwingAnalysis.id}
                            activeSwingAnalysis={activeSwingAnalysis}
                            comparisonSwingAnalysis={comparisonSwingAnalysis}
                            displayMode={displayMode}
                        />
                    ) : (
                        <div className={css.videoPlaceholder}>
                            <p
                                className={typography({
                                    size: 'h2',
                                    weight: 'bold',
                                    color: 'dark',
                                })}
                            >
                                No video found
                            </p>
                        </div>
                    )}
                </div>
                <AnimatePresence mode="wait">
                    {displayMode === 'playback' ? (
                        <motion.div
                            key="playbackSetting"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{ width: '100%' }}
                        >
                            <PlaybackSettings />
                        </motion.div>
                    ) : (
                        <motion.div
                            key="comparisonSidebar"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <ComparisonSidebar
                                currentActivityId={currentActivity?.id}
                                favoriteSwings={favoriteSwings}
                                onNewSwingSelected={(swingId) => {
                                    setComparisonSwingByID(swingId);
                                }}
                                activities={activities}
                                activeSwingId={activeSwingId}
                                initialSelectedComparisonSwingId={comparisonSwingId}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}
