import { createContext, type PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { createClient, type AuthError, type SupabaseClient } from '@supabase/supabase-js';

import { loginWithQrCode } from '../apiMethods';
import { useGlobalStore } from '../../state/globalStore';
import DataManager from '../../DataManager';
import { SimplifiedCameraConfig } from '../types/camera';
import { fallbackCameraConfig } from '../fallbacks';
import { useBoothSession } from '../hooks/useBoothSession';

type User = {
    id: string;
    email: string;
    shouldChangePassword?: boolean;
    name?: string;
    isAdmin?: boolean;
};

type UpdateUser = {
    email?: string;
    name?: string;
};

type SessionContextType = {
    user: User | null;
    supabaseClient: SupabaseClient;
    updateUser({ email, name }: UpdateUser): void;
    cameraConfig?: SimplifiedCameraConfig;
    isLoading: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    login(email: string, password: string, boothSessionId?: string): Promise<void>;
    loginWithToken(accessToken: string, refreshToken: string): Promise<void>;
    logout(): Promise<void>;
    setShouldChangePassword(): Promise<void>;
    changePassword(newPassword: string): Promise<void>;
    isChangingPassword: boolean;
    shouldResetPassword: boolean;
    initiateResetPassword(email: string): Promise<AuthError | null>;
    register(name: string, email: string, password: string, signupBooth: number | null): Promise<void>;
};

const supabaseClient = createClient(
    import.meta.env['VITE_PUBLIC_SUPABASE_URL'],
    import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'],
    {
        auth: {
            storageKey: 'elva-user.session',
        },
    },
);

export const SessionContext = createContext<SessionContextType | null>({
    user: null,
    supabaseClient,
    updateUser: () => null,
    isLoading: false,
    isLoggingIn: false,
    isLoggingOut: false,
    login: async () => Promise.resolve(),
    loginWithToken: async () => Promise.resolve(),
    logout: async () => Promise.resolve(),
    setShouldChangePassword: async () => Promise.resolve(),
    changePassword: async () => Promise.resolve(),
    isChangingPassword: false,
    shouldResetPassword: false,
    initiateResetPassword: async () => Promise.resolve(null),
    register: async () => Promise.resolve(),
});

// Get or create an instance of the data manager.
async function initializeDataManager(supabase: SupabaseClient, user: User, boothSessionId: number) {
    //TODO(at): Why does this run twice?
    if (!user?.id || !boothSessionId) {
        return;
    }

    const dataManager = await DataManager.initialize({
        supabase,
        userId: user.id,
        boothSessionId,
    });

    if (!dataManager) {
        throw new Error('DataManager could not be initialized');
    }

    return dataManager.cameraConfig ?? fallbackCameraConfig;
}

export const SessionProvider = ({ children }: PropsWithChildren) => {
    const [user, setUser] = useState<User | null>(null);
    const [cameraConfig, setCameraConfig] = useState<SimplifiedCameraConfig | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [shouldResetPassword, setShouldResetPassword] = useState(false);

    const {
        boothSessionDetails,
        boothSessionUsers,
        endBoothSession,
        loginUser,
        updateUserSession,
        persistBoothSession,
    } = useBoothSession();

    const setInitialUser = useCallback(
        async (checkExisting = true) => {
            if (!boothSessionDetails?.id) {
                return;
            }

            setIsLoading(true);

            const initialSession = await supabaseClient.auth.getSession();
            let userSession = initialSession?.data?.session?.user;

            if (!userSession && boothSessionUsers.length > 0) {
                // set the user session as the first user that joined the booth session
                const { accessToken: access_token, refreshToken: refresh_token } = boothSessionUsers[0]; // first user that joined the session
                const setSessionResponse = await supabaseClient.auth.setSession({ access_token, refresh_token });

                if (!setSessionResponse.error) {
                    userSession = setSessionResponse.data.session?.user;
                }
            }

            if (userSession && boothSessionUsers.length === 0 && checkExisting) {
                setIsLoading(false);
                await supabaseClient.auth.signOut();
                return;
            }

            const { id: userId, email, user_metadata } = userSession ?? {};

            if (userId && email) {
                const initialUser = {
                    id: userId,
                    email,
                    shouldChangePassword: user_metadata?.['should_change_password'],
                    name: user_metadata?.['name'] ?? email?.split('@')[0],
                    isAdmin: user_metadata?.['is_admin'],
                };
                const cameraConfig = await initializeDataManager(supabaseClient, initialUser, boothSessionDetails.id);
                setUser(initialUser);
                setCameraConfig(cameraConfig);
            } else {
                setUser(null);
            }

            // Reset
            useGlobalStore.setState({
                activeSwingID: null,
            });

            setIsLoading(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [boothSessionDetails, boothSessionUsers],
    );

    useEffect(() => {
        void setInitialUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothSessionUsers.length]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const {
            data: { subscription },
        } = supabaseClient.auth.onAuthStateChange(async (event, session) => {
            if (event === 'INITIAL_SESSION') {
                void setInitialUser();
            }

            if (event === 'SIGNED_OUT') {
                setUser(null);
            }

            if (event === 'USER_UPDATED') {
                const { id: userId, email, user_metadata } = session?.user ?? {};

                if (userId && email) {
                    const updatedUser = {
                        id: userId,
                        email,
                        shouldChangePassword: user_metadata?.['should_change_password'],
                        name: user_metadata?.['name'] ?? email?.split('@')[0],
                        isAdmin: user_metadata?.['is_admin'],
                    };
                    setUser(updatedUser);
                }
            }

            if (event === 'PASSWORD_RECOVERY') {
                if (session) {
                    const { access_token, refresh_token } = session;
                    await supabaseClient.auth.setSession({ access_token, refresh_token });
                    const initialUser = {
                        id: session.user.id,
                        email: session.user.email,
                        shouldChangePassword: true,
                        name: session.user.user_metadata?.['name'] ?? session.user.email?.split('@')[0],
                        isAdmin: session.user.user_metadata?.['is_admin'],
                    } as User;
                    setUser(initialUser);
                }
                setShouldResetPassword(true);
            }

            if (event === 'TOKEN_REFRESHED') {
                const userId = session?.user.id;

                if (userId && session) {
                    const { access_token: accessToken, refresh_token: refreshToken } = session ?? {};
                    await updateUserSession(userId, accessToken, refreshToken);
                }
            }
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supabaseClient.auth]);

    const handleLogin = async (email: string, password: string, sessionUid?: string) => {
        setIsLoggingIn(true);

        if (sessionUid) {
            const { success: qrLoginSuccess, data: qrLoginData } = await loginWithQrCode(email, password, sessionUid);
            const { boothSessionUserId } = qrLoginData;

            if (!qrLoginSuccess || !boothSessionUserId) {
                setIsLoggingIn(false);
                throw new Error('Error logging in to session');
            }

            const { accessToken: access_token, refreshToken: refresh_token } = qrLoginData.tokens;
            await supabaseClient.auth.setSession({ access_token, refresh_token });

            return;
        }

        const { success, data, error } = (await loginUser(email, password)) ?? { success: false };

        if (!success) {
            setIsLoggingIn(false);
            throw new Error(error ?? 'Error logging in');
        }

        const { accessToken: access_token, refreshToken: refresh_token } = data.tokens;
        await supabaseClient.auth.setSession({ access_token, refresh_token });

        if (!user) {
            await setInitialUser(false);
        }

        setIsLoggingIn(false);
    };

    const handleLoginWithToken = async (accessToken: string, refreshToken: string) => {
        await supabaseClient.auth.setSession({ access_token: accessToken, refresh_token: refreshToken });
        void setInitialUser();
    };

    const handleLogout = async () => {
        setIsLoggingOut(true);

        await endBoothSession();

        // Reset state
        useGlobalStore.setState({
            activeSwingID: null,
        });

        // Sign out
        await supabaseClient.auth.signOut();
        setUser(null);

        setIsLoggingOut(false);
    };

    const handleSetShouldChangePassword = async () => {
        if (!user) {
            throw new Error('User not authenticated');
        }

        const { error } = await supabaseClient.auth.updateUser({
            data: { should_change_password: true },
        });

        if (error) {
            throw new Error('Error setting should change password');
        }

        await supabaseClient.auth.signOut();
    };

    const handleChangePassword = async (newPassword: string) => {
        setIsChangingPassword(true);

        if (!user) {
            setIsChangingPassword(false);
            throw new Error('User not authenticated');
        }

        const { error: changePasswordError } = await supabaseClient.auth.updateUser({
            password: newPassword,
            data: { should_change_password: false },
        });

        if (changePasswordError) {
            setIsChangingPassword(false);
            throw new Error(changePasswordError.message ?? 'Error changing password');
        }

        setUser({ ...user, shouldChangePassword: false });
        setIsChangingPassword(false);
    };

    const handleUpdateUser = ({ email, name }: UpdateUser) => {
        void supabaseClient.auth.updateUser({
            email: email ?? user?.email,
            data: { is_admin: user?.isAdmin, name: name ?? user?.name },
        });
    };

    const handleInitiatePasswordReset = async (email: string) => {
        const { error } = await supabaseClient.auth.resetPasswordForEmail(email, {
            redirectTo: import.meta.env['VITE_PUBLIC_KIOSK_PASSWORD_RESET_URL'],
        });

        if (!error) {
            await persistBoothSession();
        }

        return error;
    };

    const handleRegister = async (name: string, email: string, password: string, signupBooth: number | null) => {
        const { error } = await supabaseClient.auth.signUp({
            email,
            password,
            options: {
                data: {
                    name,
                    signup_booth: signupBooth,
                },
            },
        });

        if (error) {
            throw error;
        }

        await setInitialUser(false);
    };

    return (
        <SessionContext.Provider
            value={{
                user,
                supabaseClient,
                updateUser: handleUpdateUser,
                cameraConfig,
                isLoading,
                isLoggingIn,
                isLoggingOut,
                login: handleLogin,
                loginWithToken: handleLoginWithToken,
                logout: handleLogout,
                setShouldChangePassword: handleSetShouldChangePassword,
                changePassword: handleChangePassword,
                isChangingPassword,
                shouldResetPassword,
                initiateResetPassword: handleInitiatePasswordReset,
                register: handleRegister,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};
