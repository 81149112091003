import { useInView } from 'react-intersection-observer';
import { Spinner, typography } from '@ui';
import { Activity } from '@core';

import * as css from './ActivityList.css';
import { ActivityListItem } from '../ActivityListItem/ActivityListItem';

export type ActivitiesListProps = {
    activities: Activity[] | undefined;
    showNoResultsMessage: boolean;
    isLoading: boolean;
    currentActivityId: number;
    hasNextPage: boolean;
    fetchNextPage: () => void;
};

export const ActivityList = ({
    activities,
    hasNextPage,
    fetchNextPage,
    showNoResultsMessage,
    isLoading,
    currentActivityId,
}: ActivitiesListProps) => {
    const { ref } = useInView({
        initialInView: true,
        onChange: (inView) => {
            if (inView && !isLoading && hasNextPage) {
                fetchNextPage();
            }
        },
    });

    return (
        <div className={css.activitiesList}>
            {!isLoading && showNoResultsMessage && (
                <div className={css.centered}>
                    <p className={typography({ variant: 'p' })}>No results</p>
                </div>
            )}

            {activities?.map((activity, index) => {
                const isCurrentActivity = activity.id === currentActivityId;
                return (
                    <ActivityListItem
                        key={activity.id}
                        isCurrentActivity={isCurrentActivity}
                        activity={activity}
                        index={index}
                        starredSwingsCount={activity.favorite_swings_count ?? 0}
                        noOfSwings={activity.swing_count ?? 0}
                    />
                );
            })}

            {isLoading && (
                <div className={css.centered}>
                    <Spinner size="m" />
                </div>
            )}

            <div ref={ref} style={{ height: '20px', width: '100%' }} />
        </div>
    );
};
