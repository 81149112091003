import { useEffect } from 'react';
import { UserSettingsStoreState, useUserSettingsStore } from '../state/userSettingsStore';
import { useActivityContext } from '../utils/contexts/ActivityContext/ActivityContext';
import { useRelaySocket } from './useRelaySocket';
import { useBoothSession } from '../utils/hooks/useBoothSession';

type SyncMessage = [
    UserSettingsStoreState['activeLayout'],
    UserSettingsStoreState['userSettings'],
    UserSettingsStoreState['playbackSettings'],
    UserSettingsStoreState['activityClub'],
    UserSettingsStoreState['activityTags'],
];

export function RelaySender() {
    const { boothSessionDetails } = useBoothSession();
    const { relayEndpoint } = boothSessionDetails!;

    const relaySocket = useRelaySocket({ serverUrl: relayEndpoint! });
    const { currentActivityId } = useActivityContext();
    const [activeLayout, userSettings, playbackSettings, activityClub, activityTags] = useUserSettingsStore(
        (state) =>
            [
                state.activeLayout,
                state.userSettings,
                state.playbackSettings,
                state.activityClub,
                state.activityTags,
            ] as SyncMessage,
    );

    // Every piece of Kiosk state that should be synced with the floor.
    useEffect(() => {
        relaySocket.sendMessage({
            activeLayout,
            userSettings,
            playbackSettings,
            activityClub,
            activityTags,
            activityId: currentActivityId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLayout, userSettings, playbackSettings, activityClub, activityTags, currentActivityId]);

    return null;
}
