import { useUserSettingsStore } from '../state/userSettingsStore';
import { RelayPayload, useRelaySocket } from './useRelaySocket';
import { useBoothSession } from '../utils/hooks/useBoothSession';

export function RelayListener() {
    const { boothSessionDetails } = useBoothSession();
    const { relayEndpoint } = boothSessionDetails!;

    useRelaySocket({ serverUrl: relayEndpoint!, onMessage: handleMessage });

    function handleMessage(message: RelayPayload) {
        useUserSettingsStore.setState({
            activeLayout: message.activeLayout,
            userSettings: message.userSettings,
            playbackSettings: message.playbackSettings,
            activityClub: message.activityClub,
            activityTags: message.activityTags,
            activityId: message.activityId,
        });
    }

    return null;
}
