import { ScreenTitle } from '../components/ScreenTitle';

import * as css from './screens.css';
import { Outlet } from 'react-router';

export default function KioskLayout() {
    return (
        <div className={css.root}>
            <ScreenTitle title="Elva Golf — Kiosk" />

            <Outlet />
        </div>
    );
}
