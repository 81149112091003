import _ from 'lodash';

import { Device, removeSwingPosition, Swing, UIParameter, Unit } from '@core';
import { ParameterRenderer } from '@ui';
import { ImplementationOf } from '../../UIComponent.types';
import { useActivityContext } from '../../../../utils/contexts/ActivityContext';
import * as floorCSS from './Parameter.floor.css';
import { useCorridorPerDevice, useGlobalStore } from '../../../../state/globalStore/globalStore';

export function getParameterValue(analysis: Swing | undefined, parameterID: string): number | undefined {
    return (
        analysis?.fullAnalysis?.data?.analysis?.parameter_values?.[parameterID]?.value ??
        analysis?.quickAnalysis?.data?.analysis?.parameter_values?.[parameterID]?.value ??
        undefined
    );
}

function calculateParameterMean(allAnalyses: Swing[], parameterID: string): number {
    return _.meanBy(allAnalyses, (analysis) => getParameterValue(analysis, parameterID) ?? 0);
}

function calculateParameterStandardDeviation(allAnalyses: Swing[], parameterID: string): number {
    const values = _.map(allAnalyses, (analysis) => getParameterValue(analysis, parameterID) ?? 0);
    const mean = _.mean(values);

    const squaredDifferences = _.map(values, (value) => {
        const difference = value - mean;
        return difference * difference;
    });

    // since it's population standard deviation
    // and not sample standard deviation
    // variance = (sum / N) instead of (sum / n-1)
    const variance = _.mean(squaredDifferences);

    return Math.sqrt(variance);
}

// Get the latest swing or the active swing depending on which device this is.
const getSwing = (currentDevice: Device, swings: Swing[], uuid: string | null) =>
    (currentDevice === 'floor' && swings.length) || !uuid ? swings[0] : _.find(swings, { uuid });

export type ParameterExtraProps = {
    onClick?: (node: UIParameter) => void;
    isSelected?: boolean;
};

export const Parameter: ImplementationOf<'parameter', ParameterExtraProps> = ({
    node,
    currentDevice,
    onClick,
    isSelected,
}) => {
    const parameterName = node.name?.value ?? node.id;
    const parameterID = node.parameter.id;
    const parameterUnit = node?.parameter?.unit as Unit;
    let mean = 0;
    let std = 0; // Standard deviation

    const title = node.metadata.hide_position_in_name ? removeSwingPosition(parameterName) : parameterName;

    const { swings } = useActivityContext();
    const [activeSwingID, comparisonSwingID, showComparison] = useGlobalStore((state) => [
        state.activeSwingID,
        state.comparisonSwingID,
        state.showComparison,
    ]);

    const activeSwing = getSwing(currentDevice, swings, activeSwingID);
    const comparisonSwing = getSwing(currentDevice, swings, comparisonSwingID);

    const parameterValue = getParameterValue(activeSwing, parameterID);
    const comparisonParameterValue = getParameterValue(comparisonSwing, parameterID);

    const corridorPerDevice = useCorridorPerDevice();
    const parameterCorridorMargins = corridorPerDevice[currentDevice]?.parameter_margins?.[parameterID] ?? null;

    if (!node.parameter) {
        return <> </>;
    }

    // Don't render if no unit
    if (_.isNil(parameterUnit)) {
        return <> </>;
    }

    if (swings) {
        mean = calculateParameterMean(swings, parameterID);
        std = calculateParameterStandardDeviation(swings, parameterID);
    }

    return (
        <ParameterRenderer
            corridorParameterMargins={parameterCorridorMargins}
            value={parameterValue}
            title={title}
            unit={parameterUnit}
            statistics={{ mean, std }}
            currentDevice={currentDevice}
            cssOverrides={currentDevice === 'floor' ? floorCSS : undefined}
            onClick={onClick ? () => onClick(node) : undefined}
            isSelected={isSelected}
            comparisonValue={showComparison ? comparisonParameterValue : undefined}
        />
    );
};
