import type { AnyUINode, Device, Empty, Layout, MetadataOf, UINodeTree, UINodeType } from '@core';

export interface UIComponentProps<TNode extends AnyUINode = AnyUINode> {
    readonly uiNodeTree: UINodeTree;
    readonly currentDevice: Device;
    readonly currentNode: TNode;
    readonly layout?: Layout;
    readonly metadataOverride?: MetadataOf<TNode>;
}

export interface UIComponentImplementationProps<T extends UINodeType = UINodeType> {
    readonly node: Extract<AnyUINode, { id: `${T}.${string}`; type: T }>;
    readonly children: UIComponentProps[];
    readonly currentDevice: Device;
    readonly uiNodeTree: UINodeTree;
}

export type ImplementationOf<T extends UINodeType, TExtraProps = Empty> = (
    props: UIComponentImplementationProps<T> & TExtraProps,
) => JSX.Element;

export function isPropsForType<TNode extends AnyUINode>(
    props: UIComponentProps,
    type: TNode['type'],
): props is UIComponentProps & { currentNode: TNode; metadataOverride: TNode['metadata'] } {
    return props.currentNode?.type === type;
}
