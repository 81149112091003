import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Container, VStack, HStack, ElvaLogoName, Input, Card } from '@ui';
import { colors, Divider, Button } from '@ui';

import * as css from './RegisterScreen.css';
import { useSession } from '../../utils/hooks/useSession';
import { useBoothSession } from '../../utils/hooks/useBoothSession';

type FormValues = {
    name: string;
    email: string;
    password: string;
};

type RegisterScreenProps = {
    returnTo: string;
};

export const RegisterScreen = ({ returnTo }: RegisterScreenProps) => {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { register: registerUser } = useSession();
    const { boothId } = useBoothSession();

    const navigate = useNavigate();
    const { register, handleSubmit } = useForm<FormValues>();

    const handleLogin = async ({ name, email, password }: FormValues) => {
        setIsLoading(true);
        try {
            await registerUser(name, email, password, boothId);
            navigate(returnTo);
        } catch (error) {
            setError(error instanceof Error ? error.message : 'Registration failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Card>
                <VStack spacing={20}>
                    <HStack center style={{ justifyContent: 'space-between' }}>
                        <VStack spacing={5}>
                            <h4>Register</h4>
                            <p style={{ fontSize: '0.8em', color: colors.gray[400] }}>Create your account</p>
                        </VStack>
                        <ElvaLogoName width={90} />
                    </HStack>
                    <Divider />
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <HStack center spacing={60}>
                            <VStack spacing={20} className={css.inputsContainer}>
                                {error && <span className={css.errorMessage}>{error}</span>}
                                <Input
                                    {...register('name')}
                                    required
                                    type="text"
                                    label="Name"
                                    placeholder="Your name"
                                />
                                <Input
                                    {...register('email')}
                                    required
                                    type="email"
                                    label="Email address"
                                    placeholder="you@example.com"
                                />
                                <Input
                                    {...register('password')}
                                    required
                                    type="password"
                                    label="Password"
                                    placeholder="Enter your password"
                                />
                                <Button type="submit" isLoading={isLoading} variant="primary">
                                    Register
                                </Button>
                            </VStack>
                        </HStack>
                    </form>
                </VStack>
            </Card>
        </Container>
    );
};
