import { useMemo } from 'react';
import { createWithEqualityFn } from 'zustand/traditional';
import { subscribeWithSelector } from 'zustand/middleware';
import _ from 'lodash';

import type { Corridor, IVideo } from '@core';
import type { GlobalStoreState } from './globalStore.types';
import { Device } from '@core';
import { useUserSettingsStore } from '../userSettingsStore';
import { useActivityContext } from '../../utils/contexts/ActivityContext';

const emptyReplay = {
    activeId: null,
    videos: [],

    onReplayEnded: () => {},
};

/**
 * Important guidelines for Global Store
 * ------------------------------------------
 *
 * This is for storing application-wide state that reflects user interactions.
 * e.g. non-persistent UI toggles and preferences.
 *
 * ❌ Don't store database query results here.
 */

export const useGlobalStore = createWithEqualityFn<GlobalStoreState>()(
    subscribeWithSelector((set, get) => ({
        activeSwingID: null,
        comparisonSwingID: null,
        activePosition: 1,
        currentFrame: 0,
        activityStats: [],
        activeAnnotation: null,
        scrubberProgress: 0,
        showComparison: false,
        forceHideSkeleton: false,
        lastBeastStatus: 'IDLE',
        instantReplay: emptyReplay,
        clubs: [],
        corridors: [],
        isAnalyzing: false,
        isConnectedToBeast: false,
        isConnectedToRelay: false,
    })),
);

export const triggerInstantReplayByID = (uuid: string, videos: IVideo[]) => {
    const resetReplay = () => {
        useGlobalStore.setState({
            instantReplay: emptyReplay,
        });
    };

    useGlobalStore.setState({
        instantReplay: {
            activeId: uuid,
            videos,
            onReplayEnded: resetReplay,
        },
    });
};

export const setActiveSwingByID = (uuid: string) => {
    const activeSwingId = useGlobalStore.getState().activeSwingID;

    if (activeSwingId !== uuid) {
        // When another swing is selected, reset the scrubber and positional state.
        useGlobalStore.setState({
            scrubberProgress: 0,
            currentFrame: 0,
            activePosition: 1,
            activeSwingID: uuid,
        });
    }
};

export const setComparisonSwingByID = (uuid: string | null) => {
    if (!uuid) {
        return useGlobalStore.setState({ showComparison: false, comparisonSwingID: null });
    }

    return useGlobalStore.setState({
        showComparison: true,
        comparisonSwingID: uuid,
    });
};

export const useCorridorPerDevice = () => {
    const [corridor, showCorridorOn] = useUserSettingsStore(
        (state) => [state.activeCorridor, state.userSettings?.showCorridorOn || []] as const,
    );

    return useMemo(() => {
        return _.transform(
            showCorridorOn,
            (acc, device) => {
                acc[device] = corridor!;
                return acc;
            },
            {} as { [device in Device]: Corridor },
        );
    }, [corridor, showCorridorOn]);
};

export const useSelectedAnalysis = (latest = false) => {
    const { swings } = useActivityContext();
    const swingID = useGlobalStore((state) => state.activeSwingID);

    // Either get the latest (newest) swing, or the active swing.
    let activeSwing;
    if (latest) {
        activeSwing = swings[0];
    } else {
        activeSwing = _.find(swings, (s) => s.uuid === swingID);
    }

    if (!activeSwing) return null;

    return activeSwing?.fullAnalysis ?? activeSwing?.quickAnalysis;
};

export const useAnalysisByID = (uuid: string | null) => {
    const { swings } = useActivityContext();

    if (typeof uuid !== 'string') {
        return null;
    }

    const activeSwing = _.find(swings, (s) => s.uuid === uuid);

    if (!activeSwing) return null;

    return activeSwing?.fullAnalysis ?? activeSwing?.quickAnalysis;
};
