import _ from 'lodash';
import { Table, TableInsert } from './services/supabase.service';
import {
    Mutable,
    UINodeRelation,
    Layout,
    RedefineProperty,
    ALL_DEVICES,
    LayoutCustomization,
    DEFAULT_KEY_PARAMETER_ID,
    Nil,
    isFiniteNumber,
    asFinitePositiveNumberOrNull,
    DEFAULT_LAYOUT_NAME,
} from '@core';
import { HttpException, HttpStatus } from './http-exception';
import { SupabaseClient } from '@supabase/supabase-js';

type NewLayout = TableInsert<'layouts'>;
type NewLayoutCustomization = TableInsert<'layout_customizations'>;

type ExistingLayout = Layout & { customizations: Table<'layout_customizations'>[] };

/**
 * Endpoint for getting all of the user's layouts and their customizations
 */
export async function getUserLayouts(supabaseClient: SupabaseClient, userID: string): Promise<Layout[]> {
    // fetch user's layouts
    const layoutsFromDB = await getExistingLayouts(supabaseClient, userID);

    // convert the fetched layouts to the Layout type
    const layouts = _.map(
        layoutsFromDB,
        (layout) =>
            ({
                ...layout,
                customizations: _.map(layout.customizations, (c) => _.omit(c, 'id', 'layout_id') as UINodeRelation),
            }) as Layout,
    );

    // make sure default stuff is present
    enforceDefaults(layouts, userID);

    return layouts;
}

/**
 * Endpoint for getting a single layout and its customizations
 */
export async function getUserLayout(supabaseClient: SupabaseClient, userID: string, layoutID: number): Promise<Layout> {
    // fetch layout
    const layoutIDToFetch =
        layoutID < 0
            ? (await supabaseClient.from('layouts').select('id').eq('owner_user_id', userID).limit(1))?.data?.[0]?.id ??
              -1
            : layoutID;
    const layoutFromDB =
        layoutIDToFetch < 0
            ? {
                  id: -1,
                  owner_user_id: userID,
                  name: DEFAULT_LAYOUT_NAME,
                  customizations: [],
              }
            : await getExistingLayout(supabaseClient, userID, layoutIDToFetch);

    // convert the fetched layout to the Layout type
    const layout = {
        ...layoutFromDB,
        customizations: _.map(layoutFromDB.customizations, (c) => _.omit(c, 'id', 'layout_id') as UINodeRelation),
    } as Layout;

    // make sure default stuff is present
    enforceDefaults([layout], userID);

    return layout;
}

/**
 * Endpoint for updating all user's layouts and customizations at once.
 * This endpoint deletes all of the user's layouts and customizations that are not present in the request.
 */
export async function updateUserLayouts(
    supabaseClient: SupabaseClient,
    userID: string,
    layouts: Layout[],
): Promise<Layout[]> {
    validateLayouts(userID, layouts);

    try {
        // fetch existing layouts
        const existingLayouts = await getExistingLayouts(supabaseClient, userID);

        // full join layouts and existingLayouts on id
        const newLayoutsWithNoID = _.filter(layouts, (l) => !(l.id > 0));
        const layoutsByID = _(layouts)
            .without(...newLayoutsWithNoID)
            .keyBy((l) => l.id)
            .value();
        const existingLayoutsByID = _.keyBy(existingLayouts as ExistingLayout[], (l) => l.id);
        const allLayoutIDs = _.union(_.keys(layoutsByID), _.keys(existingLayoutsByID));

        // group layouts by delete and upsert
        const { layoutsToUpsert, layoutsToDelete } = _(allLayoutIDs)
            .map((id) => ({
                layout: layoutsByID[id] as Layout | null,
                existingLayout: existingLayoutsByID[id] as ExistingLayout | null,
            }))
            .concat(
                _.map(newLayoutsWithNoID, (layout) => ({
                    layout,
                    existingLayout: null,
                })),
            )
            .groupBy(({ layout }) => (layout ? 'layoutsToUpsert' : 'layoutsToDelete'))
            .value() as {
            layoutsToUpsert: { layout: Layout; existingLayout: ExistingLayout | Nil }[];
            layoutsToDelete: { layout: Nil; existingLayout: ExistingLayout }[];
        };

        // delete layouts
        if (!_.isEmpty(layoutsToDelete)) {
            const idsToDelete = _.map(layoutsToDelete, (d) => d.existingLayout.id);
            const { error: deleteError } = await supabaseClient.from('layouts').delete().in('id', idsToDelete);

            if (deleteError)
                throw new HttpException(
                    'error deleting layouts:' + JSON.stringify(deleteError),
                    HttpStatus.INTERNAL_SERVER_ERROR,
                );
        }

        // upsert layouts
        if (!_.isEmpty(layoutsToUpsert)) {
            const layoutsToSave = _.map(layoutsToUpsert, ({ layout: { id, owner_user_id, name } }) =>
                id > 0 ? { id, owner_user_id, name } : { owner_user_id, name },
            );
            const { data: savedLayouts, error: upsertError } = await supabaseClient
                .from('layouts')
                .upsert(layoutsToSave, { defaultToNull: false })
                .select();

            if (upsertError)
                throw new HttpException(
                    'error inserting/updating layouts:' + JSON.stringify(upsertError),
                    HttpStatus.INTERNAL_SERVER_ERROR,
                );

            // apply new ids to new layouts
            for (const [before, after] of _.zip(layoutsToSave, savedLayouts)) if (before && after) before.id = after.id; // hope this works GIFLENS-https://media3.giphy.com/media/JULfVYQH3XkCxMV0QP/200.gif
        }

        // update customizations
        await replaceCustomizations(supabaseClient, layoutsToUpsert);

        // return the updated layouts
        return getUserLayouts(supabaseClient, userID);
    } catch (error) {
        console.error(error);
        return layouts;
    }
}

/**
 * Endpoint for creating a new layout for the user.
 */
export async function createUserLayout(
    supabaseClient: SupabaseClient,
    userID: string,
    layout: Layout,
): Promise<Layout> {
    delete (layout as Mutable<Partial<Layout>>).id; // ensure the layout is new

    validateLayouts(userID, [layout]);

    let createdLayout: Layout | Nil;

    try {
        // create the layout
        const layoutRowToInsert = toNewEmptyLayout(userID, layout);

        const { data: savedLayout, error: saveError } = await supabaseClient
            .from('layouts')
            .insert([layoutRowToInsert])
            .select()
            .single();

        if (saveError || !savedLayout)
            throw new HttpException(
                'error inserting layout:' + JSON.stringify(saveError),
                HttpStatus.INTERNAL_SERVER_ERROR,
            );

        createdLayout = {
            ...savedLayout,
            customizations: [],
        };

        // exit early if there are no customizations
        if (!_.isArray(layout.customizations) || _.isEmpty(layout.customizations)) {
            enforceDefaults([createdLayout], userID);
            return createdLayout;
        }

        // create the customizations
        const newCustomizations: NewLayoutCustomization[] = _.map(layout.customizations || [], (c) => ({
            layout_id: savedLayout.id,
            parent_ui_node_id: c.parent_ui_node_id,
            child_ui_node_id: c.child_ui_node_id,
            show_child_on: c.show_child_on,
            child_metadata: toObject(c.child_metadata),
            child_display_order: c.child_display_order,
        }));

        const { data: savedCustomizations, error: saveCustomizationsError } = await supabaseClient
            .from('layout_customizations')
            .insert(newCustomizations)
            .select();

        if (saveCustomizationsError || !savedCustomizations)
            throw new HttpException(
                'error inserting layout customizations:' + JSON.stringify(saveCustomizationsError),
                HttpStatus.INTERNAL_SERVER_ERROR,
            );

        // omit the id and layout_id from customizations
        for (const customization of savedCustomizations)
            createdLayout.customizations.push(_.omit(customization, 'id', 'layout_id') as UINodeRelation);

        // make sure default stuff is present
        enforceDefaults([createdLayout], userID);

        return createdLayout;
    } catch (error) {
        console.error(error);

        if (createdLayout) {
            // rollback the layout creation
            const { error: rollbackError } = await supabaseClient.from('layouts').delete().eq('id', createdLayout.id);

            if (rollbackError) console.error('error rolling back layout creation:', rollbackError);
            else console.log('successfully rolled back partial layout creation');
        }

        throw new HttpException('Error creating layout', HttpStatus.INTERNAL_SERVER_ERROR);
    }
}

/**
 * Endpoint for updating a single layout and its customizations
 */
export async function updateUserLayout(
    supabaseClient: SupabaseClient,
    userID: string,
    layoutID: number,
    layout: Layout,
): Promise<Layout> {
    // validate the layout
    if (!_.isFinite(layoutID)) throw new HttpException('Invalid layout ID', HttpStatus.BAD_REQUEST);
    validateLayouts(userID, [layout]);
    (layout as Mutable<Layout>).id = layoutID;

    let updatedLayout: Layout | Nil;
    const originalLayout = await getExistingLayout(supabaseClient, userID, layoutID);

    try {
        // update the layout
        const layoutRowToUpdate = toNewEmptyLayout(userID, layout);

        const { data: savedLayout, error: saveError } = await supabaseClient
            .from('layouts')
            .update(layoutRowToUpdate)
            .eq('id', layoutID)
            .select()
            .single();

        if (saveError || !savedLayout)
            throw new HttpException(
                'error updating layout:' + JSON.stringify(saveError),
                HttpStatus.INTERNAL_SERVER_ERROR,
            );

        const updatedLayout: Layout = {
            ...savedLayout,
            customizations: [],
        };

        // exit early if there are no customizations
        if (!_.isArray(layout.customizations) || _.isEmpty(layout.customizations)) {
            enforceDefaults([updatedLayout], userID);
            return updatedLayout;
        }

        // update the customizations
        (updatedLayout as Mutable<Layout>).customizations = layout.customizations;
        await replaceCustomizations(supabaseClient, [
            {
                layout: updatedLayout,
                existingLayout: originalLayout,
            },
        ]);

        return getUserLayout(supabaseClient, userID, layoutID);
    } catch (error) {
        console.error(error);
        if (updatedLayout) {
            // rollback the layout update
            const layoutToRollback = _.omit(originalLayout, 'customizations') as NewLayout;
            const { error: rollbackError } = await supabaseClient
                .from('layouts')
                .update(layoutToRollback)
                .eq('id', updatedLayout.id);
            if (rollbackError) console.error('error rolling back layout update:', rollbackError);
            else console.log('successfully rolled back partial layout update');
        }
        throw new HttpException('Error updating layout', HttpStatus.INTERNAL_SERVER_ERROR);
    }
}

/**
 * Endpoint for deleting a single layout and its customizations by ID
 */
export async function deleteUserLayout(
    supabaseClient: SupabaseClient,
    userID: string,
    layoutID: number,
): Promise<void> {
    if (!isFiniteNumber(layoutID)) throw new HttpException('Invalid layout ID', HttpStatus.BAD_REQUEST);

    // delete the layout
    const { error: deleteLayoutError } = await supabaseClient
        .from('layouts')
        .delete()
        .eq('owner_user_id', userID)
        .eq('id', layoutID);

    if (deleteLayoutError)
        throw new HttpException(
            'error deleting layout:' + JSON.stringify(deleteLayoutError),
            HttpStatus.INTERNAL_SERVER_ERROR,
        );

    // No need for us to delete customizations.
    // Customizations are deleted by the database
    // because the foreign key constraint is set to CASCADE DELETE
}

export async function getUserActiveLayoutID(supabaseClient: SupabaseClient, userID: string): Promise<number> {
    const { data, error } = await supabaseClient
        .from('user_settings')
        .select('active_layout_id')
        .eq('user_id', userID)
        .maybeSingle();

    if (error || !data) return -1;

    return data.active_layout_id ?? -1;
}

export async function setUserActiveLayoutID(
    supabaseClient: SupabaseClient,
    userID: string,
    activeLayoutID: number,
): Promise<number> {
    if (!isFiniteNumber(activeLayoutID)) throw new HttpException('Invalid active layout ID', HttpStatus.BAD_REQUEST);

    const { error } = await supabaseClient
        .from('user_settings')
        .update({ user_id: userID, active_layout_id: activeLayoutID })
        .eq('user_id', userID);

    if (error) throw new HttpException('Error setting user active layout ID', HttpStatus.INTERNAL_SERVER_ERROR);

    return activeLayoutID;
}

async function replaceCustomizations(
    supabaseClient: SupabaseClient,
    layoutsToUpsert: { layout: Layout; existingLayout: ExistingLayout | Nil }[],
) {
    // assign layout ids to customizations
    const customizationsChanges = _.map(layoutsToUpsert, ({ layout: { id, customizations }, existingLayout }) => {
        const layout_id = asFinitePositiveNumberOrNull(id) ?? asFinitePositiveNumberOrNull(existingLayout?.id) ?? -1;
        return {
            fromClient: _.map(customizations, (c) => ({ ...c, layout_id })),
            fromDB: _.map(existingLayout?.customizations ?? [], (c) => ({ ...c, layout_id })),
        };
    });

    // full join customizations and existingCustomizations on id
    const uniqueCustomizations = _(customizationsChanges)
        .flatMap((l) => l.fromClient)
        .keyBy((c) => `${c.layout_id}/${c.parent_ui_node_id}/${c.child_ui_node_id}`)
        .value();
    const uniqueExistingCustomizations = _(customizationsChanges)
        .flatMap((l) => l.fromDB)
        .keyBy((c) => `${c.layout_id}/${c.parent_ui_node_id}/${c.child_ui_node_id}`)
        .value();

    const allParentChildCombinations = _.union(_.keys(uniqueCustomizations), _.keys(uniqueExistingCustomizations));

    // group customizations by delete and upsert
    const { customizationsToUpsert, customizationsToDelete } = _(allParentChildCombinations)
        .map((key) => ({
            fromClient: uniqueCustomizations[key],
            fromDB: uniqueExistingCustomizations[key],
        }))
        .groupBy(({ fromClient }) => (fromClient ? 'customizationsToUpsert' : 'customizationsToDelete'))
        .value();

    // delete customizations
    if (!_.isEmpty(customizationsToDelete)) {
        const idsToDelete = _.map(customizationsToDelete, (d) => d.fromDB.id);
        const { error: deleteError } = await supabaseClient
            .from('layout_customizations')
            .delete()
            .in('id', idsToDelete);

        if (deleteError)
            throw new HttpException(
                'error deleting customizations:' + JSON.stringify(deleteError),
                HttpStatus.INTERNAL_SERVER_ERROR,
            );
    }

    // upsert customizations
    if (!_.isEmpty(customizationsToUpsert)) {
        const customizations = _.map(
            customizationsToUpsert,
            ({
                fromDB,
                fromClient: {
                    layout_id,
                    parent_ui_node_id,
                    child_ui_node_id,
                    child_display_order,
                    child_metadata,
                    show_child_on,
                },
            }) => {
                const dataToUpsert: NewLayoutCustomization = {
                    id: fromDB?.id,
                    layout_id,
                    parent_ui_node_id,
                    child_ui_node_id,
                    child_display_order: _.isFinite(child_display_order) ? Number(child_display_order) | 0 : null,
                    child_metadata: toObject(child_metadata),
                    show_child_on,
                };
                if (!dataToUpsert.id || !(dataToUpsert.id > 0)) delete dataToUpsert.id;
                return dataToUpsert;
            },
        );
        const { error: upsertError } = await supabaseClient
            .from('layout_customizations')
            .upsert(customizations, { defaultToNull: false });

        if (upsertError)
            throw new HttpException(
                'error inserting/updating customizations:' + JSON.stringify(upsertError),
                HttpStatus.INTERNAL_SERVER_ERROR,
            );
    }
}

async function getExistingLayouts(supabaseClient: SupabaseClient, userID: string): Promise<ExistingLayout[]> {
    if (!_.isString(userID)) throw new HttpException('Invalid user ID', HttpStatus.BAD_REQUEST);

    // fetch all user's layouts
    const { data: layoutsFromDB, error: layoutsError } = await supabaseClient
        .from('layouts')
        .select('*, customizations:layout_customizations(*)')
        .eq('owner_user_id', userID);

    if (layoutsError || !_.isArray(layoutsFromDB)) {
        console.error(`error fetching user's layouts:`, layoutsError);
        return [];
    }
    return layoutsFromDB as ExistingLayout[];
}

async function getExistingLayout(
    supabaseClient: SupabaseClient,
    userID: string | Nil,
    layoutID: number | Nil,
): Promise<ExistingLayout> {
    if (!_.isString(userID)) throw new HttpException('Invalid user ID', HttpStatus.BAD_REQUEST);
    if (!isFiniteNumber(layoutID)) throw new HttpException('Invalid layout ID', HttpStatus.BAD_REQUEST);

    // fetch the layout
    const { data: layoutFromDB, error: layoutError } = await supabaseClient
        .from('layouts')
        .select('*, customizations:layout_customizations(*)')
        .eq('owner_user_id', userID)
        .eq('id', layoutID)
        .single();

    if (layoutError || !layoutFromDB)
        throw new HttpException('Error fetching user layout:', HttpStatus.INTERNAL_SERVER_ERROR);
    return layoutFromDB as ExistingLayout;
}

function enforceDefaults(layouts: Layout[], userID: string) {
    if (_.isEmpty(layouts))
        layouts.push({
            id: -1,
            owner_user_id: userID,
            name: DEFAULT_LAYOUT_NAME,
            customizations: [],
        });

    for (const layout of layouts) {
        const hadNoCustomizationsBefore = _.isEmpty(layout.customizations);
        // enforce the presence of activity_navigation on both kiosk and floor
        const activityNavigations = _.filter(layout.customizations, (c) =>
            _.startsWith(c.child_ui_node_id, 'activity_navigation.'),
        );
        let [kiosk, floor] = _.map(['kiosk', 'floor'], (device) =>
            _.find(activityNavigations, (nav) => _.includes(nav.show_child_on, device)),
        );
        if (!kiosk && !floor)
            // if neither is present
            layout.customizations.push(
                (kiosk = floor =
                    {
                        parent_ui_node_id: null,
                        child_ui_node_id: 'activity_navigation.default',
                        child_display_order: null,
                        show_child_on: ALL_DEVICES,
                        child_metadata: {},
                    } as LayoutCustomization),
            );

        if (!kiosk)
            layout.customizations.push(
                (kiosk = {
                    parent_ui_node_id: null,
                    child_ui_node_id: 'activity_navigation.kiosk',
                    child_display_order: null,
                    show_child_on: ['kiosk'],
                    child_metadata: {},
                } as LayoutCustomization),
            );

        if (!floor)
            layout.customizations.push(
                (floor = {
                    parent_ui_node_id: null,
                    child_ui_node_id: 'activity_navigation.floor',
                    child_display_order: null,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization),
            );

        // enforce at least one parameter on activity_navigation
        for (const nav of [kiosk, floor]) {
            const parameterOnDevice = _.find(
                layout.customizations,
                (c) => c.parent_ui_node_id === nav.child_ui_node_id && _.startsWith(c.child_ui_node_id, 'parameter.'),
            );
            if (!parameterOnDevice)
                // this only gets executed twice if kiosk and floor are different activity_navigations
                layout.customizations.push({
                    parent_ui_node_id: nav.child_ui_node_id,
                    child_ui_node_id: `parameter.${DEFAULT_KEY_PARAMETER_ID}`,
                    child_display_order: null,
                    show_child_on: null,
                    child_metadata: {},
                } as LayoutCustomization);
        }

        // enforce presence of swing_foundations on kiosk
        const swingFoundationOnKiosk = _.find(
            layout.customizations,
            (x) => x.child_ui_node_id === 'swing_foundations.default_kiosk',
        );
        if (!swingFoundationOnKiosk)
            layout.customizations.push({
                parent_ui_node_id: null,
                child_ui_node_id: 'swing_foundations.default_kiosk',
                child_display_order: 0,
                show_child_on: null,
                child_metadata: {},
            } as LayoutCustomization);

        // enforce presence of the first custom module on kiosk
        // or else the layout editor wont show custom modules at all
        const customModuleOnKiosk = _.find(layout.customizations, (x) => x.child_ui_node_id === 'module.custom_0001');
        if (!customModuleOnKiosk)
            layout.customizations.push({
                parent_ui_node_id: null,
                child_ui_node_id: 'module.custom_0001',
                child_display_order: 0,
                show_child_on: [],
                child_metadata: {
                    title: 'My Custom Module',
                },
            } as LayoutCustomization);

        // enforce presence of swing_foundations on floor if there were customizations yet on this layout
        if (hadNoCustomizationsBefore) {
            layout.customizations.push(
                {
                    parent_ui_node_id: null,
                    child_ui_node_id: 'page.floor_0001',
                    child_display_order: 0,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization,
                {
                    parent_ui_node_id: 'page.floor_0001',
                    child_ui_node_id: 'swing_foundations.default_floor_p1',
                    child_display_order: 0,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization,
                {
                    parent_ui_node_id: 'page.floor_0001',
                    child_ui_node_id: 'swing_foundations.default_floor_p2',
                    child_display_order: 1,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization,
                {
                    parent_ui_node_id: 'page.floor_0001',
                    child_ui_node_id: 'swing_foundations.default_floor_p4',
                    child_display_order: 2,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization,
                {
                    parent_ui_node_id: 'page.floor_0001',
                    child_ui_node_id: 'swing_foundations.default_floor_p7',
                    child_display_order: 3,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization,
            );
        }
    }
}

function validateLayouts(userID: string, layouts: Layout[]) {
    if (!_.isString(userID)) throw new HttpException('Invalid user ID', HttpStatus.BAD_REQUEST);

    for (const layout of layouts) {
        if (!_.isObject(layout)) throw new HttpException('Invalid layout', HttpStatus.BAD_REQUEST);

        // validate customizations
        if (_.isArray(layout.customizations) && !_.isEmpty(layout.customizations)) {
            for (const c of layout.customizations) {
                const {
                    parent_ui_node_id: parent,
                    child_ui_node_id: child,
                    show_child_on: devices,
                    child_metadata: metadata,
                    child_display_order: displayOrder,
                } = c;
                if (!isValidNullableUINodeID(parent))
                    throw new HttpException(
                        'Invalid parent_ui_node_id on:' + JSON.stringify(c),
                        HttpStatus.BAD_REQUEST,
                    );
                if (!isValidUINodeID(child))
                    throw new HttpException('Invalid child_ui_node_id on:' + JSON.stringify(c), HttpStatus.BAD_REQUEST);
                if (!areValidDevicesOrNil(devices))
                    throw new HttpException('Invalid show_child_on on:' + JSON.stringify(c), HttpStatus.BAD_REQUEST);
                if (!isValidMetadataOrNil(metadata))
                    throw new HttpException('Invalid child_metadata on:' + JSON.stringify(c), HttpStatus.BAD_REQUEST);
                if (!isValidDisplayOrderOrNil(displayOrder))
                    throw new HttpException(
                        'Invalid child_display_order on:' + JSON.stringify(c),
                        HttpStatus.BAD_REQUEST,
                    );
            }
        }

        // enforce correct owner and correct id
        const mutableLayout = layout as RedefineProperty<Mutable<Layout>, 'id', number | undefined>;
        mutableLayout.owner_user_id = userID;
        if (!_.isFinite(layout.id) || Number(layout.id) < 0) mutableLayout.id = undefined; // create a new layout since the id is invalid
    }
}

function toNewEmptyLayout(userID: string, layout: Layout): NewLayout {
    const newLayout: NewLayout = {
        owner_user_id: userID,
        name: (_.isString(layout.name) && layout.name.length < 200 && layout.name.substring(0, 200)) || 'New Layout',
    };

    // only include the id if it is a valid number
    if (_.isFinite(layout.id)) newLayout.id = layout.id;

    return newLayout;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toObject(value: any): _.Dictionary<any> {
    return _.isObject(value) ? value : {};
}
function isValidUINodeID(id: string): boolean {
    return _.isString(id) && id.length <= 64 && id.length > 3 && _.includes(id, '.');
}
function isValidNullableUINodeID(id: string | Nil): boolean {
    return _.isNil(id) || isValidUINodeID(id);
}
function isValidDevice(device: string): boolean {
    return _.includes(ALL_DEVICES, device);
}
function areValidDevices(devices: string[]): boolean {
    return _.isArray(devices) && _.every(devices, isValidDevice);
}
function areValidDevicesOrNil(devices: string[] | Nil): boolean {
    return _.isNil(devices) || areValidDevices(devices);
}
function isValidMetadataOrNil(metadata: object | Nil): boolean {
    try {
        return _.isNil(metadata) || (_.isObject(metadata) && JSON.stringify(metadata).length < 10_000);
    } catch {
        return false;
    }
}
function isValidDisplayOrderOrNil(displayOrder: number | Nil): boolean {
    return _.isNil(displayOrder) || _.isFinite(displayOrder);
}
