import { Navigate, Outlet, useParams } from 'react-router';
import { useEffect } from 'react';

import Navigation from '../components/Navigation';
import UIComponentsLayout from '../components/UIComponentsLayout';
import Scrubber from '../components/Scrubber/Scrubber';
import { useUserSettingsStore } from '../state/userSettingsStore';
import OldActivityTopBar from '../components/OldActivityTopBar';
import { ScreenTitle } from '../components/ScreenTitle';

import * as css from './screens.css';
import { useActivityContext } from '../utils/contexts/ActivityContext';
import { FullAnalysisListener } from '../realtime/FullAnalysisListener';
import { RelaySender } from '../realtime/RelaySender';
import { Spinner, SystemMessageBanner } from '@ui';
import { useSession } from '../utils/hooks/useSession';
import { useGlobalStore } from '../state/globalStore';

function Details() {
    const { isCurrentActivity } = useActivityContext();
    const [activeLayout, uiNodes] = useUserSettingsStore((state) => [state.activeLayout, state.uiNodeTree]);
    const userSettingsLoaded = activeLayout && uiNodes;
    const isConnected = useGlobalStore((state) => state.isConnectedToBeast);

    return (
        <div className={css.root}>
            <ScreenTitle title="Elva Golf — Kiosk" />
            <SystemMessageBanner message="Disconnected, trying to reconnect..." isVisible={!isConnected} />

            {!isCurrentActivity && <OldActivityTopBar />}

            <div className={css.screen}>
                <Navigation isActivityReadOnly={!isCurrentActivity} />
                {userSettingsLoaded && (
                    <UIComponentsLayout uiNodeTree={uiNodes} layout={activeLayout} currentDevice="kiosk" />
                )}
                <Scrubber />
            </div>
            <Outlet />
        </div>
    );
}

function ActivityLoader({ id }: { id: string }) {
    const { showActivity, showCurrentActivity, resetActivity } = useActivityContext();

    useEffect(() => {
        if (id === 'current') {
            showCurrentActivity();
        } else {
            showActivity(Number(id));
        }
        return () => {
            resetActivity();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return null;
}

export default function ActivityDetails() {
    const { id } = useParams();
    const { user, isLoading } = useSession();

    if (isLoading) {
        return <Spinner size="l" isAbsolute />;
    }

    // If the user is not logged in, redirect to the login page
    if (!user) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <ActivityLoader id={id} />
            <Details />
            <Outlet />
            <RelaySender />
            <FullAnalysisListener />
        </>
    );
}
