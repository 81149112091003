import { useRef, type ReactNode, useState } from 'react';
import { motion, useScroll, useMotionValueEvent } from 'motion/react';

import { typography } from '../../typography';

import type { ColumnVariants } from './Screen.css';
import * as css from './Screen.css';
import classNames from 'classnames';

// The "chrome" around secondary screens.

type ScreenHeaderPartition = { start?: ReactNode; middle?: ReactNode; end?: ReactNode };

interface ScreenRootProps {
    title: string;
    header?: { static: ScreenHeaderPartition; fixed?: ScreenHeaderPartition };
    fixed?: boolean;
    children: ReactNode;
}

interface ScreenLayoutProps {
    children: ReactNode;
    span?: ColumnVariants['span'];
    sticky?: ColumnVariants['sticky'] | number;
}

interface HeaderProps {
    title: ScreenRootProps['title'];
    header: ScreenRootProps['header'];
}

const variants = {
    visible: { y: '0%' },
    hidden: { y: '-100%' },
};

function Header({ header, title }: HeaderProps) {
    const { scrollY } = useScroll();
    const ref = useRef<HTMLDivElement>(null);
    const disableScrollChecks = header?.fixed === null;
    const [isFixed, set] = useState(false);

    const fixedHeaderPartition = header?.fixed ?? header?.static;

    useMotionValueEvent(scrollY, 'change', (latest) => {
        if (disableScrollChecks) {
            return;
        }

        if (latest >= css.SCREEN_HEADER_HEIGHT + css.SCREEN_SPACING) {
            // If a Modal Dialog has prevented scroll on the body, we don't want to trigger the `fixed` behavior.
            const tempOverflowHiddenBugFix = document.body.hasAttribute('data-dialog-prevent-body-scroll');
            if (tempOverflowHiddenBugFix) {
                return;
            }

            return set(true);
        }

        if (isFixed && latest < css.SCREEN_HEADER_HEIGHT + css.SCREEN_SPACING) {
            return set(false);
        }
    });

    return (
        <header className={css.header}>
            <div
                ref={ref}
                aria-hidden={isFixed ? 'true' : 'false'}
                className={css.content({
                    type: 'static',
                    visibility: isFixed ? 'hidden' : 'visible',
                })}
            >
                <div className={css.headerPartition({ position: 'start' })}>
                    <h2 className={typography({ variant: 'h2' })}>{title}</h2>
                    {header?.static.start}
                </div>
                <div className={css.headerPartition({ position: 'middle' })}>{header?.static.middle}</div>
                <div className={css.headerPartition({ position: 'end' })}>{header?.static.end}</div>
            </div>
            <motion.div
                aria-hidden={isFixed ? 'false' : 'true'}
                tabIndex={-1}
                variants={variants}
                initial={false}
                animate={isFixed ? 'visible' : 'hidden'}
                transition={{
                    ease: 'circOut',
                    duration: isFixed ? 0.4 : 0.2,
                }}
                className={css.content({
                    type: 'fixed',
                    visibility: isFixed ? 'visible' : 'hidden',
                })}
            >
                <div className={css.headerPartition({ position: 'start' })}>
                    <h2 className={typography({ variant: 'h2' })}>{title}</h2>
                    {fixedHeaderPartition?.start}
                </div>
                <div className={css.headerPartition({ position: 'middle' })}>{fixedHeaderPartition?.middle}</div>
                <div className={css.headerPartition({ position: 'end' })}>{fixedHeaderPartition?.end}</div>
            </motion.div>
        </header>
    );
}

export const Screen = {
    Root: ({ title, header, fixed = false, children }: ScreenRootProps) => {
        return (
            <div className={classNames(css.screen, fixed && css.screenFixed)}>
                <Header header={header} title={title} />
                <main className={css.main}>{children}</main>
            </div>
        );
    },

    Column: ({ span, sticky, children }: ScreenLayoutProps) => (
        <div
            className={css.screenLayout['column']({ span, sticky: !!sticky })}
            style={typeof sticky === 'number' ? { top: sticky } : {}}
        >
            {children}
        </div>
    ),
};
