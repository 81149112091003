import '../styles/fonts.css';
import '../styles/reset.css';

import { useRef } from 'react';
import _ from 'lodash';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { Spinner } from '@ui';

import ConnectionPendingScreen from '../screens/ConnectionPendingScreen';
import FloorScreen from '../screens/FloorScreen';
import FloorConnectionPendingScreen from '../screens/FloorConnectionPendingScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import MessageOverlay from '../components/MessageOverlay/MessageOverlay';
import { EDeviceType } from '../utils/types/environment';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { UserSettingsProvider } from '../utils/contexts/UserSettingsContext';
import ClubSelection from '../screens/ClubSelection/ClubSelection';
import TagSelection from '../screens/TagSelection/TagSelection';
import UserSettings from '../screens/UserSettings/UserSettings';
import { SessionProvider } from '../utils/contexts/SessionContext';
import { useSession } from '../utils/hooks/useSession';
import { ActivityOverview } from '../screens/ActivityOverview';
import { BoothLoginScreen } from '../screens/BoothLoginScreen/BoothLoginScreen';
import { ChangePasswordScreen } from '../screens/ChangePasswordScreen';
import { RegisterScreen } from '../screens/RegisterScreen/RegisterScreen';
import { BoothSessionProvider } from '../utils/contexts/BoothSessionContext';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import LayoutEditor from '../screens/LayoutEditor';
import SwingFoundationsEditor from '../screens/SwingFoundationsEditor';
import PlaybackScreen from '../screens/PlaybackScreen';
import ActivityDetails from '../screens/ActivityDetails';

import * as css from './app.css';
import KioskLayout from '../screens/KioskLayout';
import { ActivityContextProvider } from '../utils/contexts/ActivityContext';
import { BeastListener } from '../realtime/BeastListener';

export default function App() {
    const currentDeviceType = import.meta.env['VITE_PUBLIC_DEVICE_TYPE'];
    return currentDeviceType === EDeviceType.FLOOR ? <FloorApp /> : <KioskApp />;
}

function FloorApp() {
    return (
        <BoothSessionProvider>
            <div className={css.app}>
                <AppWithUserSession returnTo="/floor" />
            </div>
        </BoothSessionProvider>
    );
}

function KioskApp() {
    const location = useLocation();

    //TODO(at): Move this to home experience
    if (location.pathname === '/change-password') {
        return (
            <SessionProvider>
                <div className={css.app}>
                    <ChangePasswordScreen
                        subtitle="In order to continue, please update your password."
                        returnToKioskOnSuccess
                    />
                </div>
            </SessionProvider>
        );
    }

    return (
        <BoothSessionProvider>
            <div className={css.app}>
                <AppWithUserSession returnTo="/kiosk" />
            </div>
        </BoothSessionProvider>
    );
}

export function AppWithUserSession({ returnTo }: { returnTo: string }) {
    const { boothSessionDetails, isLoading, isResettingPassword } = useBoothSession();
    const location = useLocation();
    const isQrLogin = location.pathname === '/login' && _.includes(location.search, 'uid=');
    const showBoothLogin = !boothSessionDetails && !isQrLogin && !isResettingPassword;
    const hasEndpoints = boothSessionDetails?.beastEndpoint && boothSessionDetails.relayEndpoint;

    if (isLoading) {
        return <Spinner size="l" isAbsolute />;
    }

    if (showBoothLogin) {
        return <BoothLoginScreen returnTo={returnTo} />;
    }

    return (
        <SessionProvider>
            <ActivityContextProvider>
                <BoothApp returnTo={returnTo} />
                {hasEndpoints && <BeastListener />}
            </ActivityContextProvider>
        </SessionProvider>
    );
}

function BoothApp({ returnTo }: { returnTo: string }) {
    const location = useLocation();
    const { isLoggingOut, isLoading, user } = useSession();

    const userSettingsStore = useRef(useUserSettingsStore()).current;

    if (isLoading) {
        return <Spinner size="l" isAbsolute />;
    }

    if (
        (user?.shouldChangePassword && location.pathname !== '/reset-password') ||
        location.pathname === '/change-password'
    ) {
        return <ChangePasswordScreen subtitle="In order to continue, please update your password." />;
    }

    return (
        <>
            {isLoggingOut && <MessageOverlay message="Ending session..." />}
            <UserSettingsProvider store={userSettingsStore}>
                <Routes>
                    <Route path="/floor" element={user ? <FloorScreen /> : <FloorConnectionPendingScreen />} />
                    <Route
                        path="/register"
                        element={user ? <Navigate to={returnTo} /> : <RegisterScreen returnTo={returnTo} />}
                    />
                    <Route path="/kiosk" element={<KioskLayout />}>
                        <Route index element={user ? <Navigate to="/kiosk/current" /> : <ConnectionPendingScreen />} />

                        {/* The Kiosk always has an active activity */}
                        <Route path=":id" element={<ActivityDetails />}>
                            <Route path="playback">
                                <Route index element={<PlaybackScreen display="playback" />} />
                                <Route path="comparison" element={<PlaybackScreen display="comparison" />} />
                            </Route>
                            <Route path="layout-editor" element={<LayoutEditor />} />
                            <Route path="user-settings" element={<UserSettings />} />
                            <Route path="clubs" element={<ClubSelection />} />
                            <Route path="tags" element={<TagSelection />} />
                            <Route path="swing-foundations-editor" element={<SwingFoundationsEditor />} />
                            <Route path="overview" element={<ActivityOverview />} />
                        </Route>
                    </Route>

                    <Route path="/login" element={user ? <Navigate to="/" /> : <ConnectionPendingScreen />} />

                    <Route path="/reset-password" element={<ResetPasswordScreen />} />

                    <Route path="*" element={<Navigate to={returnTo} />} />
                </Routes>
            </UserSettingsProvider>
        </>
    );
}
