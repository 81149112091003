// NOTE: This is the Beast "Communication" Websocket handler.
// It handles sending and receiving status messages from the Beast.
// e.g.
// SWING_VIDEOS_AVAILABLE
// ANALYSIS_AVAILABLE
// ANALYSIS_STATE
// BEAST_STATUS
// CURRENT_ACTIVITY_ID

import useWebSocket, { resetGlobalState } from 'react-use-websocket';
import { useGlobalStore } from '../state/globalStore/globalStore';
import { UserSettingsStoreState } from '../state/userSettingsStore';
import { useEffect } from 'react';

// NOTE: This is the payload we are sending to the Beast to keep the floor up to date.
export type RelayPayload = {
    activeLayout: UserSettingsStoreState['activeLayout'];
    userSettings: UserSettingsStoreState['userSettings'];
    playbackSettings: UserSettingsStoreState['playbackSettings'];
    activityClub: UserSettingsStoreState['activityClub'];
    activityTags: UserSettingsStoreState['activityTags'];
    activityId: number | null;
};

export type RelaySocketProps = {
    serverUrl: string;
    onMessage?: (data: RelayPayload) => void;
};

export type RelaySocket = {
    sendMessage: (message: RelayPayload) => void;
};

export const useRelaySocket = ({ serverUrl, onMessage }: RelaySocketProps): RelaySocket => {
    const onOpen = () => {
        useGlobalStore.setState({ isConnectedToRelay: true });
    };

    const onClose = () => {
        useGlobalStore.setState({ isConnectedToRelay: false });
    };

    const messageHandler = (message: MessageEvent) => {
        if (message.type === 'message' && typeof onMessage === 'function') {
            try {
                const data = JSON.parse(message.data);
                onMessage(data);
            } catch (e) {
                console.error('WebSocket data parsing error:', e);
                return;
            }
        }
    };

    const isSecureSockets = import.meta.env['VITE_PUBLIC_UNSECURE_SOCKETS'] !== 'true';
    const socketPrefix = isSecureSockets ? 'wss://' : 'ws://';
    const { sendJsonMessage } = useWebSocket(`${socketPrefix}${serverUrl}`, {
        onOpen: onOpen,
        onClose: onClose,
        onMessage: messageHandler,
        filter: (message) => {
            const status = JSON.parse(message.data).type;

            if (status === 'BEAST_STATUS') {
                // Not forwarding BEAST_STATUS messages.
                return false;
            }

            return true;
        },
        shouldReconnect: () => true,
        reconnectAttempts: 1000, // ~ 30 minute reconnection window
        reconnectInterval: 2000, // Try reconnecting every two secs
    });

    useEffect(() => {
        return () => {
            resetGlobalState(serverUrl);
        };
    }, [serverUrl]);

    return { sendMessage: sendJsonMessage };
};
