import _ from 'lodash';
import UIComponent from '../UIComponent';
import { MetadataOf, UIRow } from '@core';
import { ImplementationOf } from '../UIComponent.types';
import { HStack } from '@ui';

const defaults: MetadataOf<UIRow> = {
    spacing: '10px',
    alignment: 'start',
    direction: 'normal',
};

export const Row: ImplementationOf<'row'> = ({ node: { metadata }, children }) => {
    console.log('Row children', children);
    const { spacing, alignment, direction } = _.defaults(metadata, defaults);
    return (
        <HStack spacing={spacing} alignment={alignment} direction={direction}>
            {_.map(children, (props) => (
                <UIComponent key={props.currentNode.id} {...props} />
            ))}
        </HStack>
    );
};
