import { Fragment, useEffect } from 'react';
import _ from 'lodash';
import { Spinner } from '@ui';

import TopBar from '../components/TopBar';
import { useGlobalStore } from '../state/globalStore';
import { VideoReplay } from '../components/VideoReplay/VideoReplay.floor';
import { useUserSettingsStore } from '../state/userSettingsStore';
import UIComponentsLayout from '../components/UIComponentsLayout';
import { ScreenTitle } from '../components/ScreenTitle';

import * as css from './FloorScreen.css';
import { BeastListener } from '../realtime/BeastListener';
import { FullAnalysisListener } from '../realtime/FullAnalysisListener';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import { useActivityContext } from '../utils/contexts/ActivityContext/ActivityContext';
import { RelayListener } from '../realtime/RelayListener';
import { IS_DEV } from '../utils/consts';

function FloorScreenConnected({ relayEndpoint }: { relayEndpoint: string }) {
    const { showFloorActivity } = useActivityContext();
    const [layout, club, tags, activityId = -1, cloudLayout, cloudClub, uiNodeTree] = useUserSettingsStore(
        (state) =>
            [
                state.activeLayout,
                state.activityClub,
                state.activityTags,
                state.activityId,
                state.activeLayout,
                state.activityClub,
                state.uiNodeTree,
            ] as const,
    );

    // Disable on dev.
    const shouldInstantReplay = !IS_DEV;
    const instantReplay = useGlobalStore((state) => state.instantReplay);

    const activeLayout = layout || cloudLayout;
    const selectedClub = club || cloudClub;

    useEffect(() => {
        // Reset current swing state when Kiosk sends us a new activityId
        useGlobalStore.setState({
            activeSwingID: null,
        });

        // Update activity context
        if (activityId) {
            showFloorActivity(activityId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId]);

    return (
        <Fragment key="floor-screen">
            <ScreenTitle title="Elva Golf — Floor" />
            {shouldInstantReplay && instantReplay.activeId && (
                <VideoReplay
                    videos={instantReplay.videos}
                    onReplayEnded={() => {
                        instantReplay.onReplayEnded();
                    }}
                />
            )}
            <div className={css.root}>
                <TopBar
                    club={selectedClub?.short_name || ''}
                    tags={_.map(tags, (t) => t.name) || []}
                    selectedLayout={activeLayout?.name ?? 'No layout'}
                    progressBar={<> </>}
                />
                {uiNodeTree && activeLayout && (
                    <UIComponentsLayout uiNodeTree={uiNodeTree} layout={activeLayout} currentDevice="floor" />
                )}
            </div>
        </Fragment>
    );
}

function WebsocketInit() {
    return (
        <>
            <BeastListener />
            <FullAnalysisListener />
            <RelayListener />
        </>
    );
}

export default function FloorScreen() {
    const { boothSessionDetails } = useBoothSession();
    const hasEndpoints = boothSessionDetails?.beastEndpoint && boothSessionDetails?.relayEndpoint;

    if (!hasEndpoints) {
        return <Spinner size="l" isAbsolute />;
    }

    return (
        <>
            <WebsocketInit />
            {boothSessionDetails?.relayEndpoint && (
                <FloorScreenConnected relayEndpoint={boothSessionDetails.relayEndpoint} />
            )}
        </>
    );
}
