import _ from 'lodash';
import { useActivityContext } from '../utils/contexts/ActivityContext';
import { Analysis, ANALYSIS_TYPES } from '@core';
import { useEffect } from 'react';
import { AnalysisResponse, VideoResponse, AnalysisCache, BeastPayload, ImagesResponse } from './BeastListener.types';
import { triggerInstantReplayByID, useGlobalStore } from '../state/globalStore';
import { transformSkeletonToFramesFirst } from '../utils/skeletonUtils';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import { resetGlobalState } from 'react-use-websocket';
import { useBeastSocket } from './useBeastSocket';

const partialAnalysesCache: Record<string, AnalysisCache> = {};
const CAMERA_NAMES = ['back', 'down_the_line', 'face_on', 'trail_front'];

async function processPartialSwing(
    uuid: string,
    type: 'VIDEOS' | 'ANALYSIS' | 'IMAGES',
    partialSwing: VideoResponse | AnalysisResponse | ImagesResponse,
) {
    // Initialize a partial analysis if none exists.
    if (!partialAnalysesCache[uuid]) {
        partialAnalysesCache[uuid] = {
            videoData: null,
            analysisData: null,
            imageData: null,
        };
    }

    switch (type) {
        case 'VIDEOS': {
            const { total_frames, urls, dimensions } = partialSwing as VideoResponse;

            const videos = _.map(CAMERA_NAMES, (name) => ({
                name,
                url: urls[name],
                metadata: {
                    totalFrames: total_frames,
                    sourceWidth: dimensions?.sourceWidth ?? 2464,
                    sourceHeight: dimensions?.sourceHeight ?? 2064,
                },
            }));

            partialAnalysesCache[uuid].videoData = {
                uuid,
                data: {
                    videos,
                    analysis: partialAnalysesCache[uuid].analysisData?.data.analysis || null,
                },
            };

            triggerInstantReplayByID(uuid, videos);

            break;
        }
        case 'ANALYSIS': {
            const { analysis_url } = partialSwing as AnalysisResponse;

            const data = await fetch(analysis_url);
            const json = await data.json();

            const timestamp = Date.now();
            const frames = transformSkeletonToFramesFirst(json.skeleton);

            partialAnalysesCache[uuid].analysisData = {
                uuid,
                data: {
                    analysis: {
                        timestamp,
                        isQuickAnalysis: frames.length <= 10,
                        frames,
                        bones: json.bones,
                        measurements: json.measurements,
                        parameter_values: json.parameter_values,
                        segmentation: json.segmentation,
                        skeleton: json.skeleton,
                    },
                    videos: partialAnalysesCache[uuid].videoData?.data.videos || null,
                },
            };

            break;
        }
        case 'IMAGES': {
            const { urls } = partialSwing as AnalysisResponse;
            partialAnalysesCache[uuid].imageData = {
                uuid,
                data: urls,
            };
            break;
        }
    }

    const { videoData, analysisData, imageData } = partialAnalysesCache[uuid];

    // Once we have both analysis and videos, save to store
    if (videoData && analysisData && imageData) {
        // A complete swing
        const analysis: Analysis = {
            uuid,
            data: {
                videos: videoData.data.videos,
                analysis: analysisData.data.analysis,
                images: imageData.data,
            },
        };
        delete partialAnalysesCache[uuid];

        return analysis;
    }
    return null;
}

function reflectBeastStatus(status: string) {
    if (
        // Statuses we want reflected
        status === 'WAITING_FOR_BALL' ||
        status === 'ANALYZING_SWING' ||
        status === 'BALL_DETECTED' ||
        status === 'BALL_STEADY' ||
        status === 'SWING_ANALYSIS_FAILED' ||
        status === 'IDLE' ||
        status === 'INIT' ||
        status === 'FINALIZING'
    ) {
        useGlobalStore.setState({
            lastBeastStatus: status,
        });
    }
}

export function BeastListener() {
    const { boothSessionDetails } = useBoothSession();
    const { beastEndpoint } = boothSessionDetails!;

    const beastSocket = useBeastSocket({ serverUrl: beastEndpoint!, onMessage: handleBeastPayload });
    const { currentActivityId, addAnalysisToSwing } = useActivityContext();

    async function handleBeastPayload(payload: BeastPayload): Promise<void> {
        const { type, content } = payload;

        switch (type) {
            case 'BEAST_STATUS': {
                const { status } = content;
                useGlobalStore.setState({
                    isAnalyzing: status === 'ANALYZING_SWING',
                });
                reflectBeastStatus(status);
                break;
            }
            case 'SWING_VIDEOS_AVAILABLE': {
                const { swing_uuid, ...rest } = content;
                const swing = await processPartialSwing(swing_uuid, 'VIDEOS', rest);
                if (swing) {
                    addAnalysisToSwing(swing.uuid, ANALYSIS_TYPES['QUICK_ANALYSIS'], swing);
                    useGlobalStore.setState({
                        isAnalyzing: false,
                    });
                }
                break;
            }
            case 'ANALYSIS_AVAILABLE': {
                const { swing_uuid, ...rest } = content;
                const swing = await processPartialSwing(swing_uuid, 'ANALYSIS', rest);
                if (swing) {
                    addAnalysisToSwing(swing.uuid, ANALYSIS_TYPES['QUICK_ANALYSIS'], swing);
                    useGlobalStore.setState({
                        isAnalyzing: false,
                    });
                }
                break;
            }
            case 'POSITION_IMAGES_AVAILABLE': {
                const { swing_uuid } = content;
                const swing = await processPartialSwing(swing_uuid, 'IMAGES', content);
                if (swing) {
                    addAnalysisToSwing(swing.uuid, ANALYSIS_TYPES['QUICK_ANALYSIS'], swing);
                    useGlobalStore.setState({
                        isAnalyzing: false,
                    });
                }
                break;
            }
            case 'ANALYSIS_STATE': {
                const { state } = content;
                console.log('TODO: Handle analysis state', state);
                break;
            }
            case 'SWING_ANALYSIS_FAILED': {
                const { swing_uuid } = content;
                if (swing_uuid === null) return;
                reflectBeastStatus('SWING_ANALYSIS_FAILED');
                useGlobalStore.setState({
                    isAnalyzing: false,
                });
                break;
            }
        }
    }

    // Send heart beat every 5 seconds
    useEffect(() => {
        beastSocket.sendActivityId(currentActivityId);

        // Send heart beats to let the beast know we're still connected
        // We get info from the beast if the floor and kiosk clients are connected with the BEAST_STATUS message
        const interval = setInterval(() => {
            beastSocket.sendActivityId(currentActivityId);
        }, 5000);

        return () => {
            // Send null activity id to let the beast know we're not connected
            // Happens on log out
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentActivityId]);

    // Let the beast know we're not connected when the component unmounts
    useEffect(() => {
        return () => {
            // Cleanup websocket to Beast
            resetGlobalState(beastEndpoint);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beastEndpoint]);

    return null;
}
