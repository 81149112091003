import { useEffect, useState } from 'react';
import { sub } from 'date-fns';
import DatePicker from 'react-datepicker';
import { type Club } from '@core';
import { typography, Button, colors, SearchInput } from '@ui';
import { FolderStar } from '@phosphor-icons/react';

import * as css from './ActivityFilter.css';
import { ClubTableModal } from '../modals/ClubTableModal';

export type Filter = {
    keywords: string;
    startDate: Date;
    endDate: Date;
    onlyFavorites: boolean;
    club: Club | undefined;
};

type ActivityFilterProps = {
    numberOfActivities?: number;
    onFilterChange: (filter: Filter) => void;
};

export function ActivityFilter({ numberOfActivities, onFilterChange }: ActivityFilterProps) {
    const initialStartDate = sub(new Date().setHours(0, 0, 0), { years: 1 });
    const [startDate, setStartDate] = useState<Date>(initialStartDate);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [keywords, setKeywords] = useState('');
    const [onlyFavorites, setOnlyFavorites] = useState(false);
    const [club, setClub] = useState<Club | undefined>(undefined);
    const [clubModalOpen, setClubModalOpen] = useState(false);

    const resetFilter = () => {
        setClub(undefined);
        setOnlyFavorites(false);
        setKeywords('');
        setStartDate(initialStartDate);
        setEndDate(new Date());
    };

    useEffect(() => {
        onFilterChange({ keywords, startDate, endDate, onlyFavorites, club });
    }, [onFilterChange, keywords, startDate, endDate, onlyFavorites, club]);

    return (
        <>
            <div className={css.filterBox}>
                <div className={css.filterBoxTopBottom}>
                    <p
                        className={typography({
                            variant: 'h2',
                        })}
                    >
                        Activities
                    </p>
                    <p>{numberOfActivities ?? '-'}</p>
                </div>
                <div className={css.datesRange}>
                    <p
                        className={typography({
                            variant: 'h4',
                        })}
                    >
                        Date range
                    </p>
                    <div className={css.dates}>
                        <div className={css.dateBox}>
                            <p
                                className={typography({
                                    variant: 'h4',
                                })}
                            >
                                From
                            </p>
                            <DatePicker
                                className={css.date}
                                selected={startDate}
                                onChange={(date) => {
                                    if (!date) {
                                        return;
                                    }
                                    const utcDate = new Date(
                                        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
                                    );
                                    setStartDate(utcDate);
                                }}
                            />
                        </div>
                        <div className={css.dateBox}>
                            <p
                                className={typography({
                                    variant: 'h4',
                                })}
                            >
                                To
                            </p>
                            <DatePicker
                                className={css.date}
                                selected={endDate}
                                onChange={(date) => {
                                    if (!date) {
                                        return;
                                    }
                                    const utcDate = new Date(
                                        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
                                    );
                                    setEndDate(utcDate);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <SearchInput value={keywords} onChange={setKeywords} placeholder="Filter by keywords" />
                <div className={css.filterBoxTopBottom}>
                    <button
                        onClick={() => setOnlyFavorites((val) => !val)}
                        className={css.toggleIconButton({ active: onlyFavorites })}
                    >
                        <FolderStar color="inherit" />
                        <p
                            className={typography({
                                variant: 'h4',
                            })}
                        >
                            Favorites
                        </p>
                    </button>
                    <Button
                        variant="secondary"
                        icon="club"
                        iconColor={colors.blue[500]}
                        onClick={() => setClubModalOpen(true)}
                    >
                        {club ? club.long_name : 'Filter by club'}
                    </Button>
                </div>
            </div>
            <Button variant="secondary" icon="arrow-clockwise" onClick={resetFilter}>
                Reset Filters
            </Button>
            <ClubTableModal
                modalOpen={clubModalOpen}
                setModalOpen={setClubModalOpen}
                onClubClick={(club) => {
                    if (club?.id === club.id) {
                        setClub(undefined);
                    } else {
                        setClub(club);
                    }
                    setClubModalOpen(false);
                }}
                selectedClub={club}
            />
        </>
    );
}
